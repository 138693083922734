import { Component,OnInit } from '@angular/core';

@Component({
  selector: 'app-marketing-soln',
  templateUrl: './marketing-soln.component.html',
  styleUrls: ['./marketing-soln.component.scss']
})
export class MarketingSolnComponent {
  constructor() { }

  ngOnInit(): void {
  }


}
