<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Career</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Career</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<section class="about-us-area py-5">
    <div class="container px-md-5">
        <div class="row">
            <div class="col-lg-7 col-12">
                <div class="about-content">
                    <div class="about-title">
                        <!-- <h5 class="mb-1 font-family005">About Us</h5> -->
                        <p>
                            Every employee at BonTon is a valued team player and players an important part in its growth. Once you are a part of BonTon team you will be,
                        </p>
                        <ul>
                            <li><i class="bx bx-label"></i>Willing to contribute creative ideas as a problem solver</li>
                            <li><i class="bx bx-label"></i>Ready to face challenges in emerging technologies for enterprising career progression</li>
                            <li><i class="bx bx-label"></i>Ready to join teams that enjoy work and share success</li>
                            <li><i class="bx bx-label"></i>Ready to accept challenges in building unique solutions</li>
                        </ul>
                    </div>
                    <div class="about-title">
                        <h5>What to Expect...</h5>
                        <ul>
                            <li><i class="bx bx-label"></i>A stable stage for grooming your career and to learn new technologies.</li>
                            <li><i class="bx bx-label"></i>Exposure to multiple scenarios and challenges.</li>
                            <li><i class="bx bx-label"></i>New ways to challenge yourself and prove from time to time.</li>
                            <li><i class="bx bx-label"></i>Flexible and friendly atmosphere with enormous support from your team members.</li>
                            <li><i class="bx bx-label"></i>Recognition for your achievements and motivation to achieve new goals.</li>
                            <li><i class="bx bx-label"></i>A place where your ideas are valuable.</li>
                            <li><i class="bx bx-label"></i>Lots of fun and enjoyment.</li>
                        </ul>
                        <p>We are always on the lookout for talented Developers and Programmers.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-12">
                <div class="about-img">
                    <img src="assets/img/services/carrer.png" alt="Image" class="" >
                </div>
            </div>
        </div>
    </div>
</section>

<section class="main-contact-area py-5 bg-dark">
    <div class="container px-md-5">
        <div class="row">
            <div class="col-12 text-center">
                <div class="whyus p-3 p-md-4 rounded">
                    <h3>Why Work With US</h3>
                    <p>Our goal is to provide careers that are engaging, fulfilling, challenging and fun to help our people become better leaders in technology.</p>
                    <a class="default-btn" routerLink="/cv">Apply Now</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- <section class="main-contact-area py-5 bg-dark">
    <div class="container px-md-5">
        <div class="row">
            <div class="col-12">
                <div class="contact-wrap">
                    <div class="contact-form">
                        <div class="contact-title">
                            <h3 class="text-white">Join our team. To fill in and attach the resume and send us </h3>
                        </div>
                        <form [formGroup]="FormData" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <label class="font-family004">Name</label>
                                        <input class="form-control" name="name" placeholder="Full Name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                        <div *ngIf="submitted && f.name.errors">
                                            <small *ngIf="f.name.errors.required" class="form-text text-danger">Name is required</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <label class="font-family004">Email Address</label>
                                        <input type="text" name="email" placeholder="Enter email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                        <div *ngIf="submitted && f.email.errors" class="form-text text-danger">
                                            <small *ngIf="f.email.errors.required">Email is required</small>
                                            <small *ngIf="f.email.errors.email">Email must be a valid email address</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label class="font-family004">Experience</label>
                                        <input name="subject" placeholder="Enter Experience" formControlName="subject" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.subject.errors }">
                                        <div *ngIf="submitted && f.subject.errors">
                                            <small *ngIf="f.subject.errors.required" class="form-text text-danger">Experience is required</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label class="font-family004">Upload Resume</label>
                                        <input type="file" formControlName="message" class="form-control" cols="30" rows="10" [ngClass]="{ 'is-invalid': submitted && f.message.errors }">
                                        
                                        <div *ngIf="submitted && f.message.errors">
                                            <small *ngIf="f.message.errors.required" class="form-text text-danger">Resume is required</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12 mt-3">
                                    <button type="submit" class="default-btn btn-two"><span>Send Message</span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->