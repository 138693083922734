import { Component,OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprise-soln',
  templateUrl: './enterprise-soln.component.html',
  styleUrls: ['./enterprise-soln.component.scss']
})
export class EnterpriseSolnComponent {

  constructor() { }

  ngOnInit(): void {
  }


}
