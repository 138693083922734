import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homethree-faq',
    templateUrl: './homethree-faq.component.html',
    styleUrls: ['./homethree-faq.component.scss']
})
export class HomethreeFaqComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}