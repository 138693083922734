<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content pt-0">
            <h2 class="">Projects</h2>
            <ul class="">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Projects / Utility Department</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area py-5 bg-grey-light">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-7">
                <div class="services-details-content">
                    <div class="services-content">
                        <h3 class="text-c02">TNCSC CCTV</h3>
                        <p>An Internet Protocol camera, or IP camera, is a type of digital video camera that receives control data and sends image data via the Internet. They are commonly used for surveillance. Unlike analog closed-circuit television (CCTV) cameras, they require no local recording device, but only a local area network. Most IP cameras are webcams, but the term IP camera or netcam usually applies only to those used for surveillance that can be directly accessed over a network connection.</p>
                        <p>“The main functions of Corporation are to procure essential commodities like rice, sugar, wheat, etc., storage and movement of such stocks for its distribution from the Taluk operational godowns to various public distribution system outlets through the Co-operative Societies and other agencies. The Corporation also under takes procurement of paddy, hulling, and utilization of rice under decentralized procurement system.”</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5 d-flex align-items-center justify-content-center">
                <img src="assets/img/services/cctv.jpg" alt="" class="">
            </div>
        </div>
    </div>
</section>
<section class="services-details-area py-5">
    <div class="container">
        <div class="row mt-4">
            <div class="col-12 col-md-6 text-center p-2 px-md-5">
                <img src="assets/img/services/gps.jpg" alt="" class="">
            </div>
            <div class="col-12 col-md-6">
                <div class="services-details-content">
                    <div class="services-content">
                        <h3 class="text-c02">TNCSC GPS</h3>
                        <p>VTS allows you to view your entire fleet in single window real time. You can track your asset’s movement live, set Geo-fencing for alerts, check for over speed in case of fragile cargo movement, unscheduled overstays, fuel reports etc. It helps you in setting up customized dashboard for your entire fleet showing you the numbers you need- assets on active movement, idle assets, number of assets having planned movement for the day/ week, amount of pilferage asset wise as per their movement etc.</p>
                        <p class="text-c03">“BonTon is a leader in GPS and mobile tracking system by enabling APP-to-APP and APP-TO-WEB Mobility solutions and platform to power the Internet of Things (IoT).”</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-details-area py-5 bg-grey-light">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-7">
                <div class="services-details-content">
                    <div class="services-content">
                        <h3 class="text-c02">TANGEDCO</h3>
                        <p>Tamil Nadu Generation and Distribution Corporation Limited formed in the State of Tamil Nadu as a vertically integrated utility responsible for power generation and distribution. The electricity network has since been extended to all villages and towns throughout the State. The Tamil Nadu Generation & Distribution Corporation Ltd (TANGEDCO) requires million Tonnes of coal per annum (MTPA) for its Thermal Power Stations</p>
                        <p>The coal requirement is being met by indigenous coal and imported coal. The Indian Coal companies have executed Fuel Supply Agreement (FSA) with all the State Power Utilities. The indigenous coal is moved from various Coal fields to the load ports namely Haldia, Paradip and Vizag through Rail and then transported via ships to the discharge ports namely Ennore and Tuticorin ports. Our END -to-End application automates the entire coal shipping process management of TANGEDCO</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5 d-flex align-items-center justify-content-center p-md-4">
                <img src="assets/img/product/tneb.png" alt="" class="">
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->

