<section class="counter-area pt-100 pb-70">
    <div class="container">
        <div class="counter-max-wide">
            <div class="section-title white-title">
                <span class="font-family004">Why Choose Us</span>
                <h2 class="font-family005">We Have a Lot of Skills in System Integration</h2>
            </div>
            <div class="row">
                <div class="col-lg-6 col-sm-6">
                    <div class="single-counter overly-one">
                        <div class="overly-two">
                            <i class="flaticon-authentication"></i>
                            <h2 class="font-family004">400 +</h2>
                            <h3 class="font-family005">Technical Experts</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="single-counter overly-one">
                        <div class="overly-two">
                            <i class="flaticon-reliability"></i>
                            <h2 class="font-family004">145</h2>
                            <h3 class="font-family005">Available Loactions</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="single-counter overly-one">
                        <div class="overly-two">
                            <i class="flaticon-web-protection"></i>
                            <h2 class="font-family004">15</h2>
                            <h3 class="font-family005">Years of Experience</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="single-counter overly-one">
                        <div class="overly-two">
                            <i class="flaticon-innovation"></i>
                            <h2 class="font-family004">100%</h2>
                            <h3 class="font-family005">Innovative Technology</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>