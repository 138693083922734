<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content pt-0">
            <h2 class="">Solutions</h2>
            <ul class="">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Solutions / Integrated Command and Control Centre</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area pt-5 pb-70">
    <div class="container px-md-5">
        <div class="row">
            <div class="col-12 col-md-7">
                <div class="services-details-content">
                    <div class="services-content">
                        <h2 class="text-c02 text-gradient">Command and Control Centre</h2>
                        <p>A command and control centre is considered to be the core for managing disasters, respond to inconsistent conditions and various operations in a city. It is even referred to as ‘situation room.’ It is the place where the overall operations of an organization like monitoring, controlling and commanding are carried out. To collect vital information, the important communications and systems are connected in such centre. It further helps in sharing of decisive commands, warnings, and alerts quite flawlessly in huge number</p>
                        <h3>Command and Control Centre Solutions</h3>
                        <p>Nowadays to improve the performance of an organization the use of technology is a very helpful approach. Modern technology can enable monitoring and controlling the important and costly assets, data and information and systems. These Command and Control Centre Solutions can be used in any of the control centre, meeting room or coordination facility. These solutions can enhance the functioning of vital resources and systems and also the key information related to business can be accessed on appropriate time.</p>
                        <h3>There are quite a few uses of ICCC:</h3>
                        <ul>
                            <li>Raise awareness of circumstances among civic administrators by setting up the required devices and applications.</li>
                            <li>It can bring together all the different departments of a government, to regulate better result.</li>
                            <li>Start making decisions that are motivated by data in the time of emergency or during normal operation.</li>
                            <li>It can help to connect with the supporting workforce to tackle the on-field grievances and problems.</li>
                            <li>For any emergency situation or usual operation, the ICCC creates an opportunity of decision making which is data-driven.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5">
                <div class="solution-img">
                    <img src="assets/img/product/iccc.jpg" alt="Image" class="circle-5">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->

