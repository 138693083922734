<section class="our-approach-area pb-70">
    <div class="container">
        <div class="section-title approach-heading">
            <!-- <span>Our Approach</span> -->
            <h2 class="font-family002">Transforming business value chains through end-to-end services</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="approach-img">
                    <img src="assets/img/ban8.jpg" alt="Image" class="circle-5">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="approach-content">
                    <h3 class="font-family005">As organizations increasingly use technology to transform their business value chains, our end-to-end digital services and solutions help them accelerate results. </h3>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="single-approach">
                                <h3 class="font-family002 text-c07">Build your future with Idea`s</h3>
                                <!-- <h3>Execute with agility and speed</h3>
                                <h3>Operate with quality and efficiency</h3>
                                <h3>Time and Delivery</h3>
                                <p>To your organization's expertise, we add our industry experience, best practices and frameworks to help you define your business ambitions and develop a target operating model and strategic roadmap.

                                </p> -->
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="single-approach">
                                <h3 class="font-family002 text-c07">Execute with agility and speed</h3>
                                <!-- <p>Through our technical expertise, we help you build your business and technical capabilities and implement the most effective solutions for your needs.

                                </p> -->
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="single-approach">
                                <h3 class="font-family002 text-c07">Operate with quality & efficiency</h3>
                                <!-- <p>Through our operational excellence practices, we manage your operations in a way that drives sustained cost efficiency and agility, enabling you to fund your broader transformation program.

                                </p> -->
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="single-approach">
                                <h3 class="font-family002 text-c07">Stay committed to your Decisions</h3>
                                <!-- <p>We help you use the most appropriate methods and standards, e.g., design thinking, lean, domain-driven design, agile, DevSecOps and change leadership.

                                </p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>