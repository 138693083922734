<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content pt-0">
            <h2 class="">Products</h2>
            <ul class="">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Products / Education ERP</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area py-5">
    <div class="container">
        <div class="row px-md-5">
            <div class="col-12 col-md-7">
                <div class="services-details-content">
                    <div class="services-content">
                        <h3 class="text-c02">WHAT IS EDUCATION ERP?</h3>
                        <p>Education ERP in corporate terms also known as the education enterprise resource planning that comprises or single source information system for all departments across an organization. Education, ERP is a single database system that makes information and communication easy across various departments of an organization.</p>
                        <p>The major objective of education ERP is designed to interact with specific modules designed to communicate with other modules of the institute. Infinity's education ERP has covered in-depth functionalities or school, college and university that help reduce the costs of redundancy by increasing efficiency and productivity through out organization.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5">
                <div class="text-center px-md-5">
                    <img src="assets/img/product/eduErp.png" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-details-area py-3 bg-light">
    <div class="container">
        <div class="row">
            <div class="text-center text-uppercase mb-3 mb-md-4">
                <h4 class="text-c02">MODULES OF EDUCATION ERP</h4>
            </div>
            <div class="col-12 col-md-3">
                <div class="erpcard">
                    <span class="erpcard_lg">
                        <span class="erpcard_sl"></span>
                        <span class="erpcard_text">Administrator</span>
                    </span>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="erpcard">
                    <span class="erpcard_lg">
                        <span class="erpcard_sl"></span>
                        <span class="erpcard_text">Admission</span>
                    </span>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="erpcard">
                    <span class="erpcard_lg">
                        <span class="erpcard_sl"></span>
                        <span class="erpcard_text">Academic</span>
                    </span>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="erpcard">
                    <span class="erpcard_lg">
                        <span class="erpcard_sl"></span>
                        <span class="erpcard_text">Download Now</span>
                    </span>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="erpcard">
                    <span class="erpcard_lg">
                        <span class="erpcard_sl"></span>
                        <span class="erpcard_text">Exam management</span>
                    </span>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="erpcard">
                    <span class="erpcard_lg">
                        <span class="erpcard_sl"></span>
                        <span class="erpcard_text">Library Management</span>
                    </span>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="erpcard">
                    <span class="erpcard_lg">
                        <span class="erpcard_sl"></span>
                        <span class="erpcard_text">Hostel management</span>
                    </span>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="erpcard">
                    <span class="erpcard_lg">
                        <span class="erpcard_sl"></span>
                        <span class="erpcard_text">Transport Management</span>
                    </span>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="erpcard">
                    <span class="erpcard_lg">
                        <span class="erpcard_sl"></span>
                        <span class="erpcard_text">Internship</span>
                    </span>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="erpcard">
                    <span class="erpcard_lg">
                        <span class="erpcard_sl"></span>
                        <span class="erpcard_text">Physical Asset</span>
                    </span>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="erpcard">
                    <span class="erpcard_lg">
                        <span class="erpcard_sl"></span>
                        <span class="erpcard_text">Placement</span>
                    </span>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="erpcard">
                    <span class="erpcard_lg">
                        <span class="erpcard_sl"></span>
                        <span class="erpcard_text">Student Portal</span>
                    </span>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="erpcard">
                    <span class="erpcard_lg">
                        <span class="erpcard_sl"></span>
                        <span class="erpcard_text">HR (Human Resource)</span>
                    </span>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="erpcard">
                    <span class="erpcard_lg">
                        <span class="erpcard_sl"></span>
                        <span class="erpcard_text">Account</span>
                    </span>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="erpcard">
                    <span class="erpcard_lg">
                        <span class="erpcard_sl"></span>
                        <span class="erpcard_text">Purchase</span>
                    </span>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="erpcard">
                    <span class="erpcard_lg">
                        <span class="erpcard_sl"></span>
                        <span class="erpcard_text">Alumni Management</span>
                    </span>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="erpcard">
                    <span class="erpcard_lg">
                        <span class="erpcard_sl"></span>
                        <span class="erpcard_text">Visitor's Module</span>
                    </span>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="erpcard">
                    <span class="erpcard_lg">
                        <span class="erpcard_sl"></span>
                        <span class="erpcard_text">e - LEARNING</span>
                    </span>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="erpcard">
                    <span class="erpcard_lg">
                        <span class="erpcard_sl"></span>
                        <span class="erpcard_text">Canteen Management</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-details-area py-3">
    <div class="container">
        <div class="row">
            <div class="text-center mb-3 mb-md-4">
                <h4 class="text-c02">FEATURES OF EDUCATION ERP SOFTWARE SOLUTIONS</h4>
            </div>
            <div class="col-12 col-md-5 text-center p-2 px-md-5">
                <img src="assets/img/edufeature.png" alt="" class="">
            </div>
            <div class="col-12 col-md-7 px-md-4">
                <div class="services-details-content">
                    <div class="services-content">
                        <ul style="list-style: circle;">
                            <li>Fee Collection</li>
                            <li>Admission Management</li>
                            <li>Assignment Management with Rechecking</li>
                            <li>Student Enrollment</li>
                            <li>Attendance Management</li>
                            <li>Barcode Management</li>
                            <li>Transport Management</li>
                            <li>Alumni Registration and Management</li>
                            <li>Certificates Generation (Transcript, Character, Transfer)</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->

