<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content pt-0">
            <h2 class="">Projects</h2>
            <ul class="">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Projects / Smart city & safe city</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area py-5  bg-grey-light">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-7">
                <div class="services-details-content">
                    <div class="services-content">
                        <h3 class="text-c02">Smart City and Safe City</h3>
                        <p>BonTon Softwares Framework enables Cities to Build a City Level Digital Platform that can integrate City Data from various connected systems from across different departments and functions of the city and integrating all the information and actionable items onto a single City Operations Platform. Our platform provides rich visualisation , user friendly controls , graphical user interface and geospatial representation.</p>
                        <h3 class="text-c02">Smart City Initiative</h3>
                        <p>Smart city initiative is a revolutionary project initiated by the Government of India to transform the country’s urban landscape. We had the opportunity to provide our services to the smart cities initiative in Coimbatore and Vellore through the mission directorate for the implementation of smart cities in the state of Tamil Nadu under TUFIDCO (Tamilnadu Urban Finance and Infrastructure Development Corporation Ltd)</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5">
                <div class="text-center">
                    <img src="assets/img/product/smartcity.jpg" alt="" class="">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->

