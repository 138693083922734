import { Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-web-app',
  templateUrl: './web-app.component.html',
  styleUrls: ['./web-app.component.scss']
})
export class WebAppComponent {

  constructor() { }

  ngOnInit(): void {
  }


}

