import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vr-ar',
  templateUrl: './vr-ar.component.html',
  styleUrls: ['./vr-ar.component.scss']
})
export class VrArComponent {
  constructor() { }

  ngOnInit(): void {
  }


}
