<section class="about-us-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img src="assets/img/about-img.jpg" alt="Image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="about-title">
                        <span>About Us</span>
                        <h2>Without stopping for a moment we give you best technology experience discussing from our expertise to stop threats being theft or damaged.</h2>
                    </div>
                    <div class="tab">
                        <ngx-tabset>
                            <ngx-tab tabTitle="Our Experience">
                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat atque quibusdam fuga natus non necessitatibus eveniet maiores nostrum esse ut voluptates sint dolores, voluptatum consequatur ad est enim perferendis reprehenderit.</p>
                                <ul>
                                    <li><i class="bx bx-check-circle"></i> Trusted Partner</li>
                                    <li><i class="bx bx-check-circle"></i> Product Security</li>
                                    <li><i class="bx bx-check-circle"></i> System Security</li>
                                    <li><i class="bx bx-check-circle"></i> Operational Security</li>
                                </ul>
                            </ngx-tab>
                            <ngx-tab tabTitle="Why Us?">
                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat atque quibusdam fuga natus non necessitatibus eveniet maiores nostrum esse ut voluptates sint dolores, voluptatum consequatur ad est enim perferendis reprehenderit.</p>
                                <ul>
                                    <li><i class="bx bx-check-circle"></i> System Security</li>
                                    <li><i class="bx bx-check-circle"></i> Operational Security</li>
                                    <li><i class="bx bx-check-circle"></i> Trusted Partner</li>
                                    <li><i class="bx bx-check-circle"></i> Product Security</li>
                                </ul>
                            </ngx-tab>
                            <ngx-tab tabTitle="Our Approach">
                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Placeat atque quibusdam fuga natus non necessitatibus eveniet maiores nostrum esse ut voluptates sint dolores, voluptatum consequatur ad est enim perferendis reprehenderit.</p>
                                <ul>
                                    <li><i class="bx bx-check-circle"></i> Trusted Partner</li>
                                    <li><i class="bx bx-check-circle"></i> Operational Security</li>
                                    <li><i class="bx bx-check-circle"></i> Product Security</li>
                                    <li><i class="bx bx-check-circle"></i> System Security</li>
                                </ul>
                            </ngx-tab>
                        </ngx-tabset>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>