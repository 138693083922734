<div class="container">
    <div class="section-title">
        <span>Professionals</span>
        <h2>Meet Our Skilled Team</h2>
    </div>

    <div class="row">
        <div class="col-lg-4 col-sm-6">
            <div class="single-team">
                <!-- <img src="assets/img/team/team-img-1.jpg" alt="Image"> -->

                <div class="team-content">
                    <h3>Ciaran Sebastian</h3>
                    <span>Commercial Director</span>
                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-sm-6">
            <div class="single-team">
                <!-- <img src="assets/img/team/team-img-2.jpg" alt="Image"> -->

                <div class="team-content">
                    <h3>Jendoubi Bayer</h3>
                    <span>Financial Officer</span>
                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
            <div class="single-team">
                <!-- <img src="assets/img/team/team-img-3.jpg" alt="Image"> -->

                <div class="team-content">
                    <h3>Andrew Gleeson</h3>
                    <span>Chief Executive Officer</span>
                    <ul>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>