<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2 class="font-family005">Solutions</h2>
            <ul class="font-family005">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Solutions / VR/AR</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area pt-5 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-details-content">
                    <h2 class="text-capitalize font-family005">VR/AR</h2>
                    <div class="services-content">
                         <div class="services-details-one">
                            <div class="row match-height">
                                <div class="col-12">
                                    <img src="assets/img/product/vr3.jpg" alt="Image" class="fix">
                                </div>
                            </div>
                        </div>
                        <p class="font-family004 pt-3">Virtual reality (VR) is a simulated experience that employs pose tracking and 3D near-eye displays to give the user an immersive feel of a virtual world. Applications of virtual reality include entertainment (particularly video games), education (such as medical or military training) and business (such as virtual meetings).</p>
                    </div>

                    <div class="services-content">
                        <h3 class="font-family005">How it works?</h3>
                        <p class="font-family004">
                            The virtual tour can also be accessed through a virtual reality headset, allowing users to experience an immersive experience, as the user can control their physical movements in the VR environment through the controllers.
                        </p>
                    </div>

                    <div class="services-content">
                        <h3 class="font-family005">Why is it important?</h3>
                        <p class="font-family004">Virtual Reality is essential for the growth and expansion of the metaverse industry. The technology supports the vision of metaverse clusters by aiding in creating the 3D virtual world. With VR, users can immerse themselves in interactive experiences that are hard to access in the physical environment.</p>
                        <p class="font-family004">Experiencing things that have no existence through the computers is now possible with VR technology! Virtual Reality is a trending technology that gives excellent scope to diverse businesses to take a leap and simulate physical presence in the real world as well as the imaginary world. This immersive technology creates a computer-simulated environment, and the advancements offer cutting-edge solutions.
                        </p>    
                        <p class="font-family004">Virtual Reality technology entered the new realms stepping beyond the current technology. Common people are now able to access and enjoy the benefits of VR technology. VR doesn’t get restricted within the tech enthusiasts.
                        </p>
                        <p class="font-family004">Virtual Reality creates an artificial environment with software. The artificial environment gets presented to the audiences in a way which encourages them to accept and believe it as a real environment. VR technology creates primary experience focusing on two senses, i.e., vision and sound.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-sidebar">
                    <div class="services-category">
                        <ul class="font-family004">
                            <li><a routerLink="/smart-card">Smart Card Solutions <i class="bx bx-chevron-right"></i></a></li>
                            <li><a routerLink="/smart-city">Smart City and Safe City <i class="bx bx-chevron-right"></i></a></li>
                            <li><a routerLink="/vr-ar">VR / AR <i class="bx bx-chevron-right"></i></a></li>
                            <li><a routerLink="/enterprise-soln">Enterprise Solutions <i class="bx bx-chevron-right"></i></a></li>
                            <li><a routerLink="/marketing-soln">Marketing Solutions <i class="bx bx-chevron-right"></i></a></li>
                            <li><a routerLink="/web-app">Web / Mobile App <i class="bx bx-chevron-right"></i></a></li>
                            <!-- <li><a routerLink="/services-details">Penetration Testing <i class="bx bx-chevron-right"></i></a></li> -->
                        </ul>
                    </div>

                    <div class="services-pdf">
                        <ul class="font-family005">
                            <li><a href="assets/img/BT-CP.pdf"><i class="bx bxs-file-pdf"></i> Company Profile</a></li>
                            <!-- <li><a routerLink="/"><i class="bx bxs-file-doc"></i> DOC Download</a></li> -->
                        </ul>
                    </div>

                    <div class="services-contact mb-30">
                        <h3 class="font-family003">Contact us to do your new projects</h3>
                        <a routerLink="/contact" class="default-btn font-family005"><span>Contact Us</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->

