import { Component } from '@angular/core';

@Component({
  selector: 'app-eb',
  templateUrl: './eb.component.html',
  styleUrls: ['./eb.component.scss']
})
export class EbComponent {

}
