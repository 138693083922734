<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content pt-0">
            <h2 class="">Solutions</h2>
            <ul class="">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Solutions / Web or Mobile Applications</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area pt-5 pb-70">
    <div class="container px-md-5">
        <div class="row">
            <div class="col-12 col-md-7">
                <div class="services-details-content">
                    <div class="services-content">
                        <h2 class="text-c02 text-gradient">Web Development</h2>
                        <ul>
                            <li>Would you like to take your business to the next level? </li>
                            <li>Are you thinking about creating a web application that will set you apart from the available competition?</li>
                            <li>Or maybe you are looking for a trusted custom software development company who will be the right support for you throughout the entire web application development process?</li>
                        </ul>
                        <p class="text-c08">We have the right solutions for you and we will not hesitate to use them!</p>
                        <h3>Web Development Services</h3>
                        <p> We specialize in challenging projects by creating custom web development services like CRM, CMS and ERP platforms for different kinds of industries and targeted at individual users. Our team of experienced web developers will ensure that you get the quality implementation, whether you need front-end or back-end development for your future web application.</p>
                        <p>We provide the whole web app development solutions based on the initial concept, including software architecture, UX/UI design, development, and testing. As part of our services, we ensure that the delivery process runs smoothly. This increases your chances of being visible online.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5 d-flex align-items-center justify-content-center">
                <img src="assets/img/services/web.svg" alt="" class="">
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12 col-md-5 d-flex align-items-center justify-content-center">
                <img src="assets/img/services/mobileApp.webp" alt="" class="">
            </div>
            <div class="col-12 col-md-7">
                <div class="services-details-content">
                    <div class="services-content">
                        <h2 class="text-c02 text-gradient">Mobile Development</h2>
                        <ul>
                            <li>Looking for a way to get an additional source of conversions? </li>
                            <li>Would you like to be better connected with your audience through an intuitive mobile app?</li>
                        </ul>
                        <p class="text-c08">Meet a company that specializes in building mobile applications for multiple devices.</p>
                        <h3>Web Development Services</h3>
                        <p> We specialize in challenging projects by creating custom web development services like CRM, CMS and ERP platforms for different kinds of industries and targeted at individual users. Our team of experienced web developers will ensure that you get the quality implementation, whether you need front-end or back-end development for your future web application.</p>
                        <p>We provide the whole web app development solutions based on the initial concept, including software architecture, UX/UI design, development, and testing. As part of our services, we ensure that the delivery process runs smoothly. This increases your chances of being visible online.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->

