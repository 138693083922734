<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content pt-0">
            <h2 class="">Solutions</h2>
            <ul class="">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Solutions / Digital Marketing solutions</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area py-5">
    <div class="container px-md-5">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-details-content">
                    <h2 class="text-capitalize ">Digital Marketing Solutions</h2>
                    <div class="services-content">
                        <p class=" pt-3">Solutions marketing focuses its efforts on solutions for customer problems — not the products and services created by its company. Solutions marketing leverages many of the same practices as product marketing but takes the broader focus on business challenges and use cases.</p>
                    </div>

                    <div class="services-content">
                        <h3 class=" mb-0">Marketing Solutions-Uses</h3>
                        <p class="">Advanced digital marketing solutions are focused on providing high quality solutions, service and satisfaction of customer. The main strategy behind all this stuff is ultimately to take your business to new heights. It helps in maximizing the revenues.
                        </p>
                    </div>
                    <div class="services-content">
                        <h3 class=" mb-0">Why is it important?</h3>
                        <p class="">Marketing Solutions provides a wide range of strategic, creative and production services, all designed to grow your business. Our service bundle can be integrated as a full-service growth program, or as separate services to meet your specific objectives and budgets.</p>
                        <p class="">Advanced digital marketing solutions have brought a new revolution in the world of digital marketing. Digital marketing solutions provide help to newly opened start up in the initial stage and already setup business in order to make them profitable. It is a victorious situation for both the parties.
                        </p>    
                        <p class="">Marketing Solutions provides a wide range of strategic, creative and production services, all designed to grow your business.
                           </p>
                        <p class="">Get cutting-edge insights into consumer behavior, brand perception, and innovation opportunities and make data-driven decisions to optimize marketing spend and personalize customer communication. This will help your company cultivate sustainable growth and outperform competitors.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="solution-img">
                    <img src="assets/img/solution-img.png" alt="Image" class="circle-5">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->

