<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content pt-0">
            <h2 class="font-family005">About Us</h2>
            <ul class="font-family005">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">About Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<app-aboutpage-about></app-aboutpage-about>

<app-our-challenges></app-our-challenges>

<!-- <app-homeone-services></app-homeone-services> -->

<!-- <app-homeone-solution></app-homeone-solution> -->

<!-- <app-get-in-touch></app-get-in-touch> -->

<!-- <app-homeone-protect></app-homeone-protect> -->

<div class="partner-area pt-3 pb-5">
    <app-partner></app-partner>
</div>

<!-- <app-feedback></app-feedback> -->

