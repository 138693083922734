<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content pt-0">
            <h2 class="">Projects</h2>
            <ul class="">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Projects / Transport</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-7">
                <div class="services-details-content">
                    <div class="services-content">
                        <h3 class="text-c02">Smart card based Driving License and Vehicle Registration Document</h3>
                        <p>A smart card is a device that includes an embedded integrated circuit chip (ICC) that can be either a secure microcontroller or equivalent intelligence with internal memory or a memory chip alone. With an embedded microcontroller, smart cards have the unique ability to store large amounts of data, carry out their own on-card functions (e.g., encryption and mutual authentication) and interact intelligently with a smart card reader.</p>
                        
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5">
                <div class="px-md-5 ">
                    <img src="assets/img/product/smartcard2.jpg" alt="" class="">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-details-area py-5 bg-grey-light">
    <div class="container">
        <div class="row mt-4">
            <div class="col-12 col-md-6 d-flex align-itemsc-center justify-content-center p-2 px-md-5">
                <img src="assets/img/product/smartcard3.png" alt="" class="">
            </div>
            <div class="col-12 col-md-6">
                <div class="services-details-content">
                    <div class="services-content">
                        <h3 class="text-c02">Applications of smart card</h3>
                        <p class="font-weight-bold">Smart card is widely used in the following fields:</p>
                        <ul>
                            <li><i class="bx bx-check-circle text-c01"></i> Telecommunications </li>
                            <li><i class="bx bx-check-circle text-c01"></i> E-commerce</li>
                            <li><i class="bx bx-check-circle text-c01"></i> Banking applications</li>
                            <li><i class="bx bx-check-circle text-c01"></i> Government applications </li>
                            <li><i class="bx bx-check-circle text-c01"></i> Information Technology.</li>
                            <li><i class="bx bx-check-circle text-c01"></i> Transportation.</li>
                            <li><i class="bx bx-check-circle text-c01"></i> Educational Institutes.</li>
                            <li><i class="bx bx-check-circle text-c01"></i> Computer and Network Security.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-details-area py-5 bg-grey-light">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-7">
                <div class="services-details-content">
                    <div class="services-content">
                        <h3 class="text-c02">Advantages of smart card</h3>
                        <ul>
                            <li><i class="bx bx-check-circle text-c04"></i> It provides high security and privacy. </li>
                            <li><i class="bx bx-check-circle text-c04"></i> Reliability.</li>
                            <li><i class="bx bx-check-circle text-c04"></i> Time saving.</li>
                            <li><i class="bx bx-check-circle text-c04"></i> It is easy to use.</li>
                            <li><i class="bx bx-check-circle text-c04"></i> Most of the application provides 24/7 support. So, it is always available.</li>
                            <li><i class="bx bx-check-circle text-c04"></i> Provides more flexibility.</li>
                            <li><i class="bx bx-check-circle text-c04"></i> Smart cards are small and light-weighted.</li>
                            <li><i class="bx bx-check-circle text-c04"></i> Memory capacity is higher in many smart cards like memory cards.</li>
                            <li><i class="bx bx-check-circle text-c04"></i> Prevents user from fraud surroundings.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5 d-flex align-items-center justify-content-center p-md-4">
                <img src="assets/img/product/sc.jpg" alt="" class="">
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->

<!-- Start Blog Details Area -->
<!-- <div class="blog-details-area py-5">
    <div class="container">
        <div class="row match-height">
            <div class="col-12 col-md-7">
                <div class="blog-details-content">
                    <div class="blog-top-content">
                        <div class="news-content">
                            <h3 class="font-family005">SMART CARD BASED DRIVING LICENSE AND VEHICLE REGISTRATION DOCUMENT</h3>
                            <p class="font-family004">State-of-the-art security features prevent counterfeiting and fraudulent use.Future-oriented card systems will
                                mean greater mobility for most people, because they allow driving licenses to be read anywhere in the world
                                using a simple machine-based process. Cardholders benefit from compact size and robustness of the card. With
                                this system in place, travelers have the added convenience of holding a card with wide international acceptance.
                                Issuing authorities benefit chiefly from the rapid, simple verification, international readability, durability
                                and anti-forgery protection The driving license also has tactile (raised) surfaces, tactile engraved text,
                                changing colors as well as complex background designs. The smart cards come with a facility to record traffic
                                violations and details of any convictions relating to road accidents.Unique ID number – provides quick access
                                to Holders files. Smart card also prevents duplicates or fraudulent licenses. Manufacturing of Registration Certificates Card Personalization and Application Software Complete Integrated Solutions Manpower Deployment for Operations.
                            </p>
                        </div>
                        <div class="news-content font-family004 mt-3">
                            <h3 class="font-family005">DRIVING LICENSE AND REGISTRATION MANAGEMENT SOLUTIONS</h3>
                            <p> eDLVR is a web-based and modular solution designed to consolidate and simplify the various processes involved in vehicle registration and the issuance of driving licenses. 
                            <p>The solution empowers traffic police departments with an intelligent and secure mechanism that supports online registration and enrollment.</p>
                           <p>The solution is additionally available with a mobile application that can be published under the government’s eService platform.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Blog Details Area -->