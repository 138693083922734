<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content pt-0">
            <h2 class="">Products</h2>
            <ul class="">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Products / AHMS</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area py-5 bg-grey-light">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-7">
                <div class="services-details-content">
                    <div class="services-content">
                        <h2 class="text-c02">AHMS</h2>
                        <p>Human Resources Management System (HRMS) refers to a suite of software that organizations use to manage internal HR functions. From employee data management to payroll, recruitment, benefits, training, talent management, employee engagement, and employee attendance, HRMS software helps HR professionals manage the modern workforce.  HRMS systems put information about a company’s most valuable assets in front of the people who need them.</p>
                        <ul>
                            <li>Track Assets</li>
                            <li>Dashboard Analysis</li>
                            <li>Details about Case Raised and Open Cases, Solved Cases</li>
                            <li>Help Desk and Ticket Details</li>
                            <li>MTTR Calculation.</li>
                            <li>MISC Reports.</li>
                            <li>Android Application</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5 d-flex align-items-center justify-content-center">
                <img src="assets/img/product/asset_banner.png" alt="" class="">
            </div>
        </div>
    </div>
</section>
<section class="services-details-area py-5">
    <div class="container">
        <div class="row mt-4">
            <div class="col-12 col-md-6 text-center p-2 px-md-5">
                <img src="assets/img/product/AHMS2.png" alt="" class="">
            </div>
            <div class="col-12 col-md-6">
                <div class="services-details-content">
                    <div class="services-content">
                        <h2 class="text-c02">Features of AHMS</h2>
                        <ul style="list-style: square;">
                            <li>User Friendly</li>
                            <li>Responsive and High-end Design.</li>
                            <li>Mail notification on each request</li>
                            <li>Global search bar, Filters and QR Code </li>
                            <li>Asset addressing and Bulk import and export</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-details-area py-5 bg-grey-light">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-7">
                <div class="services-details-content">
                    <div class="services-content">
                        <h3 class="">Make your work more efficient</h3>
                        <div class="row mt-md-5">
                            <div class="col-12">
                                <div class="single-solution overly-one">
                                    <div class="overly-two">
                                        <!-- <h3 class=""><a>Asset - Mobile</a></h3> -->
                                        <p class="">Set a due date and time for each task. Assign tasks to individual.</p>
                                        <span>01</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="single-solution overly-one">
                                    <div class="overly-two">
                                        <!-- <h3 class=""><a>Asset - Mobile</a></h3> -->
                                        <p class="">Add, view and track all your AHMS on the go using the Asset Manager mobile app now!.</p>
                                        <span>02</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="single-solution overly-one">
                                    <div class="overly-two">
                                        <!-- <h3 class=""><a>Asset - Mobile</a></h3> -->
                                        <p class="">Scan your product barcodes or QR codes and quickly add them to the Help desk - Assets module quickly.</p>
                                        <span>03</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5 d-flex align-items-center justify-content-center p-md-4">
                <img src="assets/img/product/ahms4.png" alt="" class="">
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->

