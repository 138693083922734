<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content pt-0">
            <h2 class="">Solutions</h2>
            <ul class="">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Solutions / Video Surveillance Solutions</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-12">
                <div class="services-details-content">
                    <div class="services-content ">
                        <h2 class="">Watchful Eyes for Round-the-clock Protection</h2>
                        <p>Cameras have always been an integral part of any monitoring system and is extensively used, now more than ever. Recognizing the potential of video surveillance to enhance security, Bonton Software brings you integrated solutions to simplify as well as strengthen your video surveillance systems. The array of cutting-edge products provide an end-to-end support–from cameras and monitors to software for easy integration; collection, analysis and storage of data; all of which contribute to building a powerful and effective security system.</p>
                    </div>
                    <div class="services-content ">
                        <h2 class="">Solutions</h2>
                        <p>Smart, intuitive, easy-to-use video surveillance solutions protect your homes and businesses from theft, vandalism, and other security-related incidents..</p>
                    </div>
                    <div class="services-content ">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="block">
                                    <img src="assets/img/services/videosurv.jpg" alt="" class="img-fluid">
                                    <h2>Managed Video</h2>
                                    <p>Counter threats and identify false alarms faster by connecting your surveillance network to a managed video solution.</p>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="block">
                                    <img src="assets/img/services/services-1.jpg" alt="" class="img-fluid">
                                    <h2>Video Analytics</h2>
                                    <p>Monitor retail traffic, employee performance and maximize operational efficiencies with video analytics solutions.</p>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="block">
                                    <img src="assets/img/services/services-2.jpg" alt="" class="img-fluid">
                                    <h2>Video Surveillance System</h2>
                                    <p>At Bonton Software, we provide you with video surveillance solutions that integrate a range of smart DVRs, NVRs, video monitors and CCTV systems, tailored to your needs.</p>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="block">
                                    <img src="assets/img/services/services-3.jpg" alt="" class="img-fluid">
                                    <h2>Video Monitoring Systems</h2>
                                    <p>Keep a close eye on your premises with a fully integrated monitoring system coupled with high-tech image processing tools.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="related-post">
                        <div class="title">
                            <h2>Products</h2>
                            <p>Our cutting-edge video surveillance devices help you meet your individual security needs with remote monitoring and protection.</p>
                        </div>

                        <div class="row">
                            <div class="col-12 col-md-3">
                                <div class="border border-1 text-center">
                                    <div class="single-services">
                                        <div class="services-img">
                                            <img src="assets/img/services/video.jpg" alt="Image" class="img-fluid">
                                        </div>
                                    </div>
                                    <div class="services-content">
                                        <h3 class="Head">Video Management Systems</h3>
                                    </div>
                                </div>
                            </div>
        
                            <div class="col-12 col-md-3">
                                <div class="border border-1 text-center">
                                    <div class="single-services">
                                        <div class="services-img">
                                            <img src="assets/img/services/mobile.jpg" alt="Image" class="img-fluid">
                                        </div>
                                    </div>
                                    <div class="services-content">
                                        <h3 class="Head">Mobile Apps</h3>
                                    </div>
                                </div>
                            </div>
        
                            <div class="col-12 col-md-3">
                                <div class="border border-1 text-center">
                                    <div class="single-services">
                                        <div class="services-img">
                                            <img src="assets/img/services/monitor.webp" alt="Image" class="img-fluid">
                                        </div>
                                    </div>
                                    <div class="services-content">
                                        <h3 class="Head">Monitors</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="border border-1 text-center">
                                    <div class="single-services">
                                        <div class="services-img">
                                            <img src="assets/img/services/nvr.jpg" alt="Image" class="img-fluid">
                                        </div>
                                    </div>
                                    <div class="services-content">
                                        <h3 class="Head">Network Video Recorders</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="border border-1 text-center">
                                    <div class="single-services">
                                        <div class="services-img">
                                            <img src="assets/img/services/acceries.jpg" alt="Image" class="img-fluid">
                                        </div>
                                    </div>
                                    <div class="services-content">
                                        <h3 class="Head">Camera Accessories</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="border border-1 text-center">
                                    <div class="single-services">
                                        <div class="services-img">
                                            <img src="assets/img/services/encoders.jpg" alt="Image" class="img-fluid">
                                        </div>
                                    </div>
                                    <div class="services-content">
                                        <h3 class="Head">Encoders</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="border border-1 text-center">
                                    <div class="single-services">
                                        <div class="services-img">
                                            <img src="assets/img/services/network.jpg" alt="Image" class="img-fluid">
                                        </div>
                                    </div>
                                    <div class="services-content">
                                        <h3 class="Head">Network and Digital Video Storage</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-3">
                                <div class="border border-1 text-center">
                                    <div class="single-services">
                                        <div class="services-img">
                                            <img src="assets/img/services/ipcamera.jpg" alt="Image" class="img-fluid">
                                        </div>
                                    </div>
                                    <div class="services-content">
                                        <h3 class="Head">IP Camera</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-4 col-md-12">
                <div class="services-sidebar">
                    <div class="services-category">
                        <ul>
                            <li><a routerLink="/services-details">Cyber Security <i class="bx bx-chevron-right"></i></a></li>
                            <li><a routerLink="/services-details">Network Security <i class="bx bx-chevron-right"></i></a></li>
                            <li><a routerLink="/services-details">Threat Hunter <i class="bx bx-chevron-right"></i></a></li>
                            <li><a routerLink="/services-details">Incident Responder <i class="bx bx-chevron-right"></i></a></li>
                            <li><a routerLink="/services-details">Secure Managed IT <i class="bx bx-chevron-right"></i></a></li>
                            <li><a routerLink="/services-details">Server Monitoring <i class="bx bx-chevron-right"></i></a></li>
                            <li><a routerLink="/services-details">Penetration Testing <i class="bx bx-chevron-right"></i></a></li>
                        </ul>
                    </div>

                    <div class="services-pdf">
                        <ul>
                            <li><a routerLink="/"><i class="bx bxs-file-pdf"></i> PDF Download</a></li>
                            <li><a routerLink="/"><i class="bx bxs-file-doc"></i> DOC Download</a></li>
                        </ul>
                    </div>

                    <div class="services-contact mb-30">
                        <h3>Contact us to do your new projects</h3>
                        <a routerLink="/contact" class="default-btn"><span>Contact Us</span></a>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>
<!-- End Services Details Area -->