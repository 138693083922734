import { Component } from '@angular/core';

@Component({
  selector: 'app-coope',
  templateUrl: './coope.component.html',
  styleUrls: ['./coope.component.scss']
})
export class CoopeComponent {

}
