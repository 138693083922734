<section class="safety-area pt-5 pb-5">
    <div class="container">
        <div class="section-title">
            <!-- <span>Cyber Safety</span> -->
            <h2 >Our skilled team is excited to help you meet these demands and maximize your reach, effectiveness and return of investment.</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <div class="imgMain">
                            <img src="assets/img/customers/systemInt.png" alt="Image">
                        </div>
                        <h3 class="animate__animated animate__fadeInUp" data-wow-delay="1s"><a routerLink="/services-details">System Integration</a></h3>
                        <p>The process of linking together different computing systems and software applications physically or functionally to act as a coordinated whole.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <div class="imgMain">
                            <img src="assets/img/customers/smartcity.png" alt="Image">
                        </div>
                        <h3 class="animate__animated animate__fadeInUp" data-wow-delay="1s"><a routerLink="/services-details">Smart Cities</a></h3>
                        <p>A smart city uses ICT to improve operational efficiency. The main goal of a smart city is to optimize city functions and promote economic growth.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <div class="imgMain">
                            <img src="assets/img/customers/erp.png" alt="Image">
                        </div>
                        <h3 class="animate__animated animate__fadeInUp" data-wow-delay="1s"><a routerLink="/services-details">Enterprise ERP</a></h3>
                        <p>Enterprise resource planning (ERP) is a platform. Companies use to manage and integrate the essential parts of their businesses.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <div class="imgMain">
                            <img src="assets/img/customers/ccc.png" alt="Image">
                        </div>
                        <h3 class="animate__animated animate__fadeInUp" data-wow-delay="1s"><a routerLink="/services-details">Command & Control Center</a></h3>
                        <p>A command and control centre is considered to be the core for managing disasters, respond to inconsistent conditions and various operations in a city.It is the place where the overall operations of an organization like monitoring, controlling and commanding are carried out.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <div class="imgMain mb-md-4">
                            <img src="assets/img/customers/digiMargt.png" alt="Image">
                        </div>
                        <h3 class="animate__animated animate__fadeInUp" data-wow-delay="1s"><a routerLink="/services-details">Digital Marketing</a></h3>
                        <p>Digital marketing is the act of promoting products and services through digital channels, such as social media, SEO, email, and mobile apps.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <div class="imgMain">
                            <img src="assets/img/customers/sms.png" alt="Image">
                        </div>
                        <h3 class="animate__animated animate__fadeInUp" data-wow-delay="1s"><a routerLink="/services-details">Education</a></h3>
                        <p>To ensure that students who study in Government schools are afforded the opportunity to excel in academics, fine arts and sports, a special scheme in the name of Model Schools is being implemented.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>