<section class="feature-area pb-70">
    <div class="container">
        <div class="row match-height">
            <div class="col-lg-4 col-sm-6">
                <div class="single-feature overly-one">
                    <div class="overly-two">
                        <div class="title">
                            <i class="flaticon-testing"></i>
                            <h3 class="font-family005 text-c06">Quality</h3>
                        </div>
                        <p>Satisfying the stakeholders without compromise.

                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-feature overly-one">
                    <div class="overly-two">
                        <div class="title">
                            <i class="flaticon-cybercrime"></i>
                            <h3 class="font-family005 text-c06">Performance</h3>
                        </div>
                        <p>Perform with passion, succeed with purpose. Empower and enhance your performance.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-feature overly-one">
                    <div class="overly-two">
                        <div class="title">
                            <i class="flaticon-cyber-security"></i>
                            <h3 class="font-family005 text-c06">Value</h3>
                        </div>
                        <p>Our efforts rebukes the value of the products.

                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>