import { Component } from '@angular/core';

@Component({
  selector: 'app-education-erp',
  templateUrl: './education-erp.component.html',
  styleUrls: ['./education-erp.component.scss']
})
export class EducationErpComponent {

}
