<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content pt-0">
            <h2 class="">Products</h2>
            <ul class="">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Products / HRMS</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area py-5 bg-grey-light">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-7">
                <div class="services-details-content">
                    <div class="services-content">
                        <h2 class="text-c02">HRMS</h2>
                        <p><b>Human Resources Management System (HRMS)</b> refers to a suite of software that organizations use to manage internal HR functions. From employee data management to payroll, recruitment, benefits, training, talent management, employee engagement, and employee attendance, HRMS software helps HR professionals manage the modern workforce.  HRMS systems put information about a company’s most valuable assets in front of the people who need them</p>
                        <p><b>Human Resource Management Systems</b> are HR platforms that offer functionality across multiple HR software categories. Human resource management systems are used to manage all human resource information within an integrated, modular system.</p>
                        
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5 d-flex align-items-center justify-content-center">
                <img src="assets/img/product/hrms.jpg" alt="" class="imgBorder">
            </div>
        </div>
    </div>
</section>
<section class="services-details-area py-5">
    <div class="container">
        <div class="row mt-4">
            <div class="col-12 col-md-4 d-flex justify-content-center">
                <img src="assets/img/product/hrms2.png" alt="" class="">
            </div>
            <div class="col-12 col-md-8">
                <h2 class="">HRMS Features</h2>
                <div class="row py-md-3">
                    <div class="col-12 col-md-6 my-2">
                        <div class="hrMain">
                            <span class="iconHead">
                                <i class="bx bx-check-circle text-c02 font-22"></i>
                            </span>
                            <span class="textParg">
                                Employee Management
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 my-2">
                        <div class="hrMain">
                            <span class="iconHead">
                                <i class="bx bx-check-circle text-c02 font-22"></i>
                            </span>
                            <span class="textParg">
                                Financial Management
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 my-2">
                        <div class="hrMain">
                            <span class="iconHead">
                                <i class="bx bx-check-circle text-c02 font-22"></i>
                            </span>
                            <span class="textParg">
                                Document Management
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 my-2">
                        <div class="hrMain">
                            <span class="iconHead">
                                <i class="bx bx-check-circle text-c02 font-22"></i>
                            </span>
                            <span class="textParg">
                                Time & Attendance Management
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 my-2">
                        <div class="hrMain">
                            <span class="iconHead">
                                <i class="bx bx-check-circle text-c02 font-22"></i>
                            </span>
                            <span class="textParg">
                                Application Tracking and Recruiting
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 my-2">
                        <div class="hrMain">
                            <span class="iconHead">
                                <i class="bx bx-check-circle text-c02 font-22"></i>
                            </span>
                            <span class="textParg">
                                performance Management
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 my-2">
                        <div class="hrMain">
                            <span class="iconHead">
                                <i class="bx bx-check-circle text-c02 font-22"></i>
                            </span>
                            <span class="textParg">
                                Learning & Professional Development
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 my-2">
                        <div class="hrMain">
                            <span class="iconHead">
                                <i class="bx bx-check-circle text-c02 font-22"></i>
                            </span>
                            <span class="textParg">
                                Payroll Management
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 my-2">
                        <div class="hrMain">
                            <span class="iconHead">
                                <i class="bx bx-check-circle text-c02 font-22"></i>
                            </span>
                            <span class="textParg">
                                Compensation Management
                            </span>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 my-2">
                        <div class="hrMain">
                            <span class="iconHead">
                                <i class="bx bx-check-circle text-c02 font-22"></i>
                            </span>
                            <span class="textParg">
                                Benefits Management
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->

