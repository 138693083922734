<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2 class="">Solutions</h2>
            <ul class="">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Solutions</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Solution Area -->
<section class="solution-area pt-5 pb-70">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="solution-content">
                    <div class="solution-title">
                        <h2 class="text-capitalize ">All-in One Tech Solution</h2>
                        <p class=""> BonTon Softwares  is Transforming the  E-governance solutions, Technology Consulting, and IT infrastructure, Cloud Services. 
                            We have delivered many System Integration Projects and Supply Chain Management, Networking Solutions, Video Surveillance, Command Control Centers, Smart Data Centers,Smart card solutions and 3G/4G Wireless connectivity solutions.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 col-md-6">
                            <div class="single-solution overly-one">
                                <div class="overly-two">
                                    <h3 class=""><a routerLink="/solutions-details">Commitment</a></h3>
                                    <p class="">Your success is a reflection of your decisions, attitude and thinking. If your business isn't where you want to it to be or you're ready to set higher goals, start making new decisions. To grow your business, you have to grow yourself.</p>
                                    <span>01</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-6">
                            <div class="single-solution overly-one">
                                <div class="overly-two">
                                    <h3 class=""><a routerLink="/solutions-details"> Strategy</a></h3>
                                    <p class="">The strategic plan gives us a place to record our mission, vision, and values, as well as your long-term goals and the action plans you'll use to reach them and we follow them.</p>
                                    <span>02</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-6 offset-md-3 offset-lg-0">
                            <div class="single-solution overly-one">
                                <div class="overly-two">
                                    <h3 class=""><a routerLink="/solutions-details">Deploy</a></h3>
                                    <p class="">We have multiple ways to deploy a new version of an application and it really depends on the needs and budget. When releasing to development/staging environments, a recreate or ramped deployment is usually a good choice.</p>
                                    <span>03</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 pr-0">
                <div class="solution-img">
                    <img src="assets/img/solution-img.png" alt="Image" class="circle-5">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Solution Area -->

<!-- <app-homeone-services></app-homeone-services> -->