import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-details-page',
  templateUrl: './services-details-page.component.html',
  styleUrls: ['./services-details-page.component.scss']
})
export class ServicesDetailsPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
