<section class="banner-area banner-area-three">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-start">
                    <div class="col-lg-8">
                        <div class="banner-content py-3">
                            <!-- <span class="top-title animate__animated animate__fadeInDown" data-wow-delay="1s">BonTon Software Integrated</span> -->
                            <h1 class="animate__animated animate__fadeInDown" data-wow-delay="1s">Where Innovation meets Excellence <br/>BonTon is all about uncompromising excellence.</h1>
                            <p class="animate__animated animate__fadeInUp" data-wow-delay="1s">We are here to change the game and add real value with technology at its best
                                Technology <br/> that improves our customers way of functioning.</p>
                            <div class="banner-btn animate__animated animate__fadeInUp" data-wow-delay="1s">
                                <a routerLink="/contact" class="default-btn"><span>Contact Us</span></a>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-lg-6">
                        <div class="banner-img animate__animated animate__fadeInDown" data-wow-delay="1s">
                            <img src="assets/img/banner/banner-img.png" alt="Image">
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>