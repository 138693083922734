<section class="services-area pt-65 pb-65">
    <!-- <div class="container"> -->
        <!-- <div class="section-title">
            <span>Our Services 
            </span>
            <h2>Core Values of Solutions Provided by us</h2>
        </div> -->

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <a routerLink="/smart-card">
                            <img src="https://www.smartrfidcards.com/uploads/allimg/200827/2-15b51UN-43F.jpg" alt="Image">
                        </a>
                    </div>
                    <div class="services-content font-family004">
                        <h3 class="font-family005"><a routerLink="/smart-card">SMART CARD SOLUTIONS</a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p> -->
                        <a routerLink="/smart-card" class="read-more">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <a routerLink="/smart-city">
                            <img src="assets/img/services/services-8.jpg" alt="Image">
                        </a>
                    </div>
                    <div class="services-content font-family004">
                        <h3 class="font-family005"><a routerLink="/smart-city">SMART CITY & SAFE CITY</a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p> -->
                        <a routerLink="/smart-city" class="read-more">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <a routerLink="/civil-supply">
                            <img src="assets/img/services/services-2.jpg" alt="Image">
                        </a>
                    </div>
                    <div class="services-content font-family004">
                        <h3 class="font-family005"><a routerLink="/civil-supply"> VIDEO
                            SURVEILLANCE SYSTEM</a></h3>
                        <!-- <p>.</p> -->
                        <a routerLink="/civil-supply" class="read-more">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <a routerLink="/vr-ar">
                            <img src="assets/img/services/services-9.jpg" alt="Image">
                        </a>
                    </div>
                    <div class="services-content font-family004">  
                        <h3 class="font-family005"><a routerLink="/vr-ar">VR / AR</a></h3>
                        <div class="content">
                            <!-- <p>Sysg systems and software applications physically or functionally to
                                act as a coordinated whole.</p> -->
                            <a routerLink="/vr-ar" class="read-more">Read More <i class="flaticon-right-arrow"></i></a>
                        </div>
                    </div>
                </div>
            </div>

        

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-10.jpg" alt="Image">
                        </a>
                    </div>
                    <div class="services-content font-family004">
                        <h3 class="font-family005"><a routerLink="/services-details">
                            AI / ML</a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p> -->
                        <a routerLink="/services-details" class="read-more">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

           

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-5.jpg" style="margin-top: -90px;" alt="Image">
                        </a>
                    </div>
                    <div class="services-content font-family004">
                        <h3 class="font-family005"><a routerLink="/services-details">Internet of Things (IoT)
                            </a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p> -->
                        <a routerLink="/services-details" class="read-more">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-6.jpg" style="height: 100%;" alt="Image">
                        </a>
                    </div>
                    <div class="services-content font-family004">
                        <h3 class="font-family005"><a routerLink="/services-details">CLOUD SERVICES</a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p> -->
                        <a routerLink="/services-details" class="read-more">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-7.jpg" alt="Image">
                        </a>
                    </div>
                    <div class="services-content font-family004">
                        <h3 class="font-family005"><a routerLink="/services-details">MANAGED IT OUTSOURCING
                            </a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p> -->
                        <a routerLink="/services-details" class="read-more">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

           
        </div>
    <!-- </div> -->
</section>