<section class="challenges-area pt-100 pb-70">
    <div class="container">
        <div class="section-title white-title">
            <!-- <span>Our Methodology</span> -->
            <h2 class="font-family003">
                <!-- Our Innovative Products and Solutions Empower Companies which enable business growth and efficiency. -->
                Our skilled team is excited to help you meet these demands and maximize your reach, effectiveness and return of investment.
            </h2>
        </div>

        <div class="row match-height">
            <!-- <div class="col-md-4 col-12">
                <div class="single-challenges overly-one">
                    <div class="overly-two">
                        <img src="assets/img/customers/1.png" alt="test" class="avatar img-100 rounded-circle p-3 my-4">
                        <h3 class="font-018 font-family004">Smart Kavalar</h3>
                        <p>To seek police assistance instantly in emergency situations such as physical emergencies, eve teasing, kidnapping or natural disasters etc.</p>
                        <span class="flaticon-threat"></span>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-12">
                <div class="single-challenges overly-one">
                    <div class="overly-two">
                        <img src="assets/img/customers/3.png" alt="test" class="avatar img-100 rounded-circle p-3 my-4">
                        <h3 class="font-018 font-family004">Smart DL/RC</h3>
                        <p>The Driving License and Registration Certificate is upgraded to Smart Card version. The DL/RC Certificate’s information is stored electronically in a microprocessor chip. The data of an individual is stored in the server.</p>
                        <span class="flaticon-cyber-security-1"></span>
                    </div>
                </div>
            </div> -->
            <div class="col-md-4 col-12">
                <div class="single-challenges overly-one">
                    <div class="overly-two">
                        <img src="assets/img/customers/2.png" alt="test" class="avatar img-100 rounded-circle p-3 my-4">
                        <h3 class="font-018 font-family003">System Integration</h3>
                        <p>The process of linking together different computing systems and software applications physically or functionally to act as a coordinated whole.</p>
                        <span class="flaticon-password"></span>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-12">
                <div class="single-challenges overly-one">
                    <div class="overly-two">
                        <img src="assets/img/customers/4.png" alt="test" class="avatar img-100 rounded-circle p-3 my-4">
                        <h3 class="font-018 font-family004">Smart Cities</h3>
                        <p>A smart city uses ICT to improve operational efficiency. The main goal of a smart city is to optimize city functions and promote economic growth.</p>
                        <span class="flaticon-password"></span>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-12">
                <div class="single-challenges overly-one">
                    <div class="overly-two">
                        <!-- <i class="flaticon-password"></i> -->
                        <img src="assets/img/customers/erp.png" alt="test" class="avatar img-100 rounded-circle p-3 my-4">
                        <h3 class="font-018 font-family004">Enterprise ERP</h3>
                        <p>Enterprise resource planning (ERP) is a platform. Companies use to manage and integrate the essential parts of their businesses.</p>
                        <span class="flaticon-password"></span>
                    </div>
                </div>
            </div>

            <!-- <div class="col-md-4 col-12">
                <div class="single-challenges overly-one">
                    <div class="overly-two">
                        <img src="assets/img/customers/2.png" alt="test" class="avatar img-100 rounded-circle p-3 my-4">
                        <h3 class="font-018 font-family004">Road Safety</h3>
                        <p>We deployed this app mainly to provide safety precaution to avoid accident.</p>
                        <span class="flaticon-cyber"></span>
                    </div>
                </div>
            </div> -->

            <div class="col-md-4 col-12">
                <div class="single-challenges overly-one">
                    <div class="overly-two">
                        <!-- <i class="flaticon-password"></i> -->
                        <img src="assets/img/customers/sl.png" alt="test" class="avatar img-100 rounded-circle p-3 my-4">
                        <h3 class="font-018 font-family003">Command & Control Center</h3>
                        <p>A command and control centre is considered to be the core for managing disasters, respond to inconsistent conditions and various operations in a city.It is the place where the overall operations of an organization like monitoring, controlling and commanding are carried out.</p>
                        <span class="flaticon-password"></span>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-12">
                <div class="single-challenges overly-one">
                    <div class="overly-two">
                        <!-- <i class="flaticon-password"></i> -->
                        <img src="assets/img/customers/dm.png" alt="test" class="avatar img-100 rounded-circle p-3 my-4">
                        <h3 class="font-018 font-family003">Digital Marketing</h3>
                        <p>Digital marketing is the act of promoting products and services through digital channels, such as social media, SEO, email, and mobile apps.</p>
                        <span class="flaticon-password"></span>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-12">
                <div class="single-challenges overly-one">
                    <div class="overly-two">
                        <!-- <i class="flaticon-password"></i> -->
                        <img src="assets/img/customers/edu.png" alt="test" class="avatar img-100 rounded-circle p-3 my-4">
                        <h3 class="font-018 font-family003">Education</h3>
                        <p>To ensure that students who study in Government schools are afforded the opportunity to excel in academics, fine arts and sports, a special scheme in the name of Model Schools is being implemented.</p>
                        <span class="flaticon-password"></span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>