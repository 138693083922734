import { Component } from '@angular/core';

@Component({
  selector: 'app-iccc',
  templateUrl: './iccc.component.html',
  styleUrls: ['./iccc.component.scss']
})
export class IcccComponent {

}
