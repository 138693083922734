<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2 class="font-family005">TANGEDCO</h2>
            <ul class="font-family005">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">TANGEDCO</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<div class="blog-details-area py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-8">
                <div class="blog-details-content">
                    <div class="blog-top-content">
                        <div class="news-content">
                            <h3 class="font-family005 mb-2">TANGEDCO:</h3>
                            <p class="font-family004">Tamil Nadu Generation and Distribution Corporation Limited formed in the State of Tamil Nadu as a vertically integrated utility responsible for power generation and distribution. The electricity network has since been extended to all villages and towns throughout the State. The Tamil Nadu Generation & Distribution Corporation Ltd (TANGEDCO) requires million Tonnes of coal per annum (MTPA) for its Thermal Power Stations.</p>
                            <p class="font-family004">The coal requirement is being met by indigenous coal and imported coal. The Indian Coal companies have executed Fuel Supply Agreement (FSA) with all the State Power Utilities. The indigenous coal is moved from various Coal fields to the load ports namely Haldia, Paradip and Vizag through Rail and then transported via ships to the discharge ports namely Ennore and Tuticorin ports. Our END -to-End application automates the entire coal shipping process management of TANGEDCO</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="row">
                    <div class="col-12">
                        <div class="single-blog-post-img">
                            <a>
                                <img src="assets/img/product/tneb.png" alt="Image" class="img-fluid">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Blog Details Area -->