import { Component } from '@angular/core';

@Component({
  selector: 'app-civil-supply',
  templateUrl: './civil-supply.component.html',
  styleUrls: ['./civil-supply.component.scss']
})
export class CivilSupplyComponent {

}
