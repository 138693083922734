<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content pt-0">
            <h2 class="">Products</h2>
            <ul class="">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Products / VTS</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-7">
                <div class="services-details-content">
                    <div class="services-content">
                        <h2 class="text-c02">Vehicle Tracking System</h2>
                        <p>Vehicle tracking system is software and hardware system enabling the vehicle owner to track the position of their vehicle.</p>
                        <p>Vehicle tracking system module is attached to each vehicle.This circuit is designed for tracking the location of vehicles. Most of tracking systems are made by using GPS. Tracking systems are mostly used by fleet operators for tracking a vehicle location, routing and others. This is a very good method for preventing our vehicles from stolen. This tracking system sends us the geographical coordinates and by using these coordinates we can track our vehicle position on electronic maps using internet. By using these tacking systems we can share real time information about transportations. And also can be share real time information or position bus/taxi/cab with passengers.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5">
                <div class="d-flex align-items-center justify-content-center p-md-5">
                    <img src="assets/img/product/vts.png" alt="" class="">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-details-area py-5 bg-grey-light">
    <div class="container">
        <div class="row mt-4">
            <div class="col-12 col-md-6 text-center p-2 px-md-5">
                <img src="assets/img/product/vts2.png" alt="" class="">
            </div>
            <div class="col-12 col-md-6">
                <div class="services-details-content">
                    <div class="services-content">
                        <h2 class="text-c02">Features of VTS</h2>
                        <ul style="list-style: square;">
                            <li>Effective Fleet Control</li>
                            <li>Instant Fuel Level Monitoring</li>
                            <li>Decreasing the Accident Risk</li>
                            <li>Driver and Load Security</li>
                            <li>Monitoring the Delivery Time</li>
                            <li>Fuel and Power Saving</li>
                            <li>Speeding Alerts & Vehicle Maintenance</li>
                            <li>Route Optimization & Scheduling</li>
                            <li>Evaluation of drivers Performance</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->

