<header class="header-area">
    <div class="navbar-area" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item"><a routerLink="" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" routerLink= "/solutions" class="nav-link">Solutions <i class="bx bx-chevron-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/smart-card" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Smart Card Solutions </a></li>
                                <!-- <li class="nav-item"><a routerLink="/smart-city" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Smart City & Safe City</a></li> -->
                                <li class="nav-item"><a routerLink="/video-surv" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Video Surveillance Solutions</a></li>
                                <li class="nav-item"><a routerLink="/enterprise-soln" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">ERP / SCM Solutions</a></li>
                                <li class="nav-item"><a routerLink="/web-app" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Web / Mobile Application</a></li>
                                <li class="nav-item"><a routerLink="/iccc" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}"> Integrated Command & Control Center</a></li>
                                <li class="nav-item"><a routerLink="/iot" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Internet of Things</a></li>
                                <li class="nav-item"><a routerLink="/marketing-soln" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Digital Marketing</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" routerLink= "/solutions" class="nav-link">Products<i class="bx bx-chevron-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/smart-card" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">AHSM</a></li>
                                <li class="nav-item"><a routerLink="/vr-ar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">HRMS</a></li>
                                <li class="nav-item"><a routerLink="/enterprise-soln" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Vehicle Tracking system (VTS)</a></li>
                                <li class="nav-item"><a routerLink="/marketing-soln" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Lab Inventory Management system (LIMS)</a></li>
                            </ul>
                        </li>
                        <!-- <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About </a></li> -->
                        <li class="nav-item">
                        <a href="javascript:void(0)" routerLink="/work" class="nav-link">Projects <i class="bx bx-chevron-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/police" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Transport Department</a></li>
                                <li class="nav-item"><a routerLink="/smart-city" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Police Department</a></li>
                                <li class="nav-item"><a routerLink="/civil-supply" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Utility Department </a></li>
                                <li class="nav-item"><a routerLink="/transport" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Education Department</a></li>
                                <li class="nav-item"><a routerLink="/eb" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Smart City & Safe City Projects</a></li>
                                <li class="nav-item"><a routerLink="/manufacturing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">e-Governance Projects</a></li>
                            </ul>                        
                        </li>
                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Careers </a></li>
                        <!--    <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Pages <i class="bx bx-chevron-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                                <li class="nav-item">
                                    <a href="javascript:void(0)" class="nav-link">Services <i class="bx bx-chevron-right"></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/services-style-one" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Style One</a></li>
                                        <li class="nav-item"><a routerLink="/services-style-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Style Two</a></li>
                                        <li class="nav-item"><a routerLink="/services-style-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Style Three</a></li>
                                        <li class="nav-item"><a routerLink="/services-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Services Details</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team</a></li>
                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>
                                <li class="nav-item"><a routerLink="/profile-authentication" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Login/Register</a></li>
                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>
                                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>
                                <li class="nav-item"><a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>
                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>
                                <li class="nav-item"><a routerLink="/not-found" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error Page</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Solutions <i class="bx bx-chevron-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/solutions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Solutions</a></li>
                                <li class="nav-item"><a routerLink="/solutions-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Solution Details</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Shop <i class="bx bx-chevron-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/products" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products</a></li>
                                <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a></li>
                                <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a></li>
                                <li class="nav-item"><a routerLink="/products-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products Details</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Blog <i class="bx bx-chevron-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog-grid" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>
                                <li class="nav-item"><a routerLink="/blog-right-sidebar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Right Sidebar</a></li>
                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li> -->
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                    </ul>
                    <div class="others-option">
                        <!-- <div class="cart-icon">
                            <a routerLink="/cart"><i class="bx bx-cart"></i><span>0</span></a>
                        </div> -->
                        <!-- href="https://bontonsoftwares.com/webmail" -->
                        <div class="get-quote">
                            <a href="https://mail.google.com/mail" class="default-btn"><span>Login</span></a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</header>