<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content font-family005">
            <h2>Projects</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Projects</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area py-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="services-details-content">
                    <div class="services-content font-family004">
                        <h2 class="font-family005">ML / AI</h2>
                        <p>Machine Learning involves the use of Artificial Intelligence to enable machines to learn a task from experience without programming them specifically about that task. (In short, Machines learn automatically without human hand holding!!!) This process starts with feeding them good quality data and then training the machines by building various machine learning models using the data and different algorithm.</p>
                    </div>

                    <div class="services-content font-family004">
                        <h3 class="font-family005">Internet of Things[IOT]</h3>
                        <p>IoT stands for Internet of Things. It refers to the interconnectedness of physical devices, such as appliances and vehicles, that are embedded with software, sensors, and connectivity which enables these objects to connect and exchange data. This technology allows for the collection and sharing of data from a vast network of devices, creating opportunities for more efficient and automated systems.</p>
                    </div>
                    <div class="services-content font-family004">
                        <h3 class="font-family005">Cloud Computing</h3>
                        <p>Cloud computing is on-demand access, via the internet, to computing resources—applications, servers (physical servers and virtual servers), data storage, development tools, networking capabilities, and more—hosted at a remote data center managed by a cloud services provider (or CSP). The CSP makes these resources available for a monthly subscription fee or bills them according to usage.</p>
                    </div>
                    <div class="services-content font-family004">
                        <h3 class="font-family005">IT Ooutsourcing Services</h3>
                        <p>Businesses today entirely rely on IT Infrastructure and Cloud solutions. In the past decade, outsourcing IT services to Managed Service Providers (MSP) has increased. Top companies take the leverage to focus on their core business by outsourcing IT services. IT Outsourcing (also called IT Facility Management services, ICT services i.e. Information and Communication Technology Services) is a business practice of opting for contracted IT services from IT Service Providers responsible for operating and maintaining IT Infrastructure.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="related-post">
                <div class="title font-family005">
                    <h2>Related Photos</h2>
                </div>

                <div class="row">
                    <div class="col-12 col-md-3">
                        <div class="single-services">
                            <div class="services-img">
                                <a routerLink="/services-details">
                                    <img src="assets/img/services/services-1.jpg" alt="Image">
                                </a>
                            </div>
                            <div class="services-content font-family004">
                                <h3><a routerLink="/services-details">Website Scanning</a></h3>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-3">
                        <div class="single-services">
                            <div class="services-img">
                                <a routerLink="/services-details">
                                    <img src="assets/img/services/services-2.jpg" alt="Image">
                                </a>
                            </div>
                            <div class="services-content font-family004">
                                <h3><a routerLink="/services-details">Malware Removal</a></h3>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-3">
                        <div class="single-services">
                            <div class="services-img">
                                <a routerLink="/services-details">
                                    <img src="assets/img/services/services-3.jpg" alt="Image">
                                </a>
                            </div>
                            <div class="services-content font-family004">
                                <h3><a routerLink="/services-details">Cloud Security</a></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->