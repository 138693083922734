import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hometwo-approach',
  templateUrl: './hometwo-approach.component.html',
  styleUrls: ['./hometwo-approach.component.scss']
})
export class HometwoApproachComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
