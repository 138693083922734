<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content pt-0">
            <h2 class="">Projects</h2>
            <ul class="">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Projects / Education Department</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area py-5  bg-grey-light">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-7">
                <div class="services-details-content">
                    <div class="services-content">
                        <h3 class="text-c02">School Management System</h3>
                        <p>School Management System carried on by any individual or institution engaged in providing a services to students, teachers, guardians and other persons are intermediary that performs one or more of the following functionalities – Student Admission, Employee Registration, Student List, Employee List, Student Attendance, Employee Attendance, Student Routine, Result Management, Payroll & Accounts.</p>
                        <p>It is a management information system helps to manage the different processes in an educational institution like General Administration, Staff Management, Academics, Student Management, and Accounts etc. The information is made using the latest technologies and help’s to make decision making a lot faster, effective and easier than ever before. Also helps to improve the overall quality of education of the institution.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5">
                <div class="text-center">
                    <img src="assets/img/product/sms.png" alt="" class="">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-details-area py-5">
    <div class="container">
        <div class="row mt-4">
            <div class="col-12 col-md-6 text-center p-2 px-md-5">
                <img src="assets/img/product/sms2.webp" alt="" class="">
            </div>
            <div class="col-12 col-md-6">
                <div class="services-details-content">
                    <div class="services-content">
                        <h3 class="text-c02">Benefits of SMS</h3>
                        <ul style="list-style: square;">
                            <li>It saves instructors valuable time from administrative tasks</li>
                            <li>Very beneficial in report making</li>
                            <li>It closes the communication gap</li>
                            <li>It handles the lifetime data of students</li>
                            <li>Timetable managemen</li>
                            <li>It provides safety for students</li>
                            <li>Program Management and Class Scheduling</li>
                            <li>Academic & Role Management</li>
                            <li>Financial and Assessment Management</li>
                            <li>Hostel Management</li>
                            <li>Examination</li>
                            <li>Fees Management</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->

