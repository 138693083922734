import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutPageComponent } from './components/pages/about-page/about-page.component';
// import { BlogDetailsPageComponent } from './components/pages/blog-details-pag/blog-details-page.component';
import { BlogGridPageComponent } from './components/pages/blog-grid-page/blog-grid-page.component';
import { BlogRightSidebarPageComponent } from './components/pages/blog-right-sidebar-page/blog-right-sidebar-page.component';
import { CartPageComponent } from './components/pages/cart-page/cart-page.component';
import { CheckoutPageComponent } from './components/pages/checkout-page/checkout-page.component';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { PricingPageComponent } from './components/pages/pricing-page/pricing-page.component';
import { PrivacyPolicyPageComponent } from './components/pages/privacy-policy-page/privacy-policy-page.component';
import { ProductsDetailsPageComponent } from './components/pages/products-details-page/products-details-page.component';
import { ProductsPageComponent } from './components/pages/products-page/products-page.component';
import { ProfileAuthenticationPageComponent } from './components/pages/profile-authentication-page/profile-authentication-page.component';
import { ServicesDetailsPageComponent } from './components/pages/services-details-page/services-details-page.component';
import { ServicesPageStyleOneComponent } from './components/pages/services-page-style-one/services-page-style-one.component';
import { ServicesPageStyleThreeComponent } from './components/pages/services-page-style-three/services-page-style-three.component';
import { ServicesPageStyleTwoComponent } from './components/pages/services-page-style-two/services-page-style-two.component';
import { SolutionsDetailsPageComponent } from './components/pages/solutions-details-page/solutions-details-page.component';
import { SolutionsPageComponent } from './components/pages/solutions-page/solutions-page.component';
import { TeamPageComponent } from './components/pages/team-page/team-page.component';
import { TermsConditionsPageComponent } from './components/pages/terms-conditions-page/terms-conditions-page.component';
import { EnterpriseSolnComponent } from './components/pages/enterprise-soln/enterprise-soln.component';
import { IotComponent } from './components/pages/iot/iot.component';
import { MarketingSolnComponent } from './components/pages/marketing-soln/marketing-soln.component';
import { MlAiComponent } from './components/pages/ml-ai/ml-ai.component';
import { SmartCardDetailsComponent } from './components/pages/smart-card-details/smart-card-details.component';
import { SmartCityComponent } from './components/pages/smart-city/smart-city.component';
import { VideoSurvSysComponent } from './components/pages/video-surv-sys/video-surv-sys.component';
import { VrArComponent } from './components/pages/vr-ar/vr-ar.component';
import { WebAppComponent } from './components/pages/web-app/web-app.component';
import { WorkComponent } from './components/pages/work/work.component';
import { PoliceComponent } from './components/pages/police/police.component';
import { TransportComponent } from './components/pages/transport/transport.component';
import { EbComponent } from './components/pages/eb/eb.component';
// import { markTimeline } from 'console';
import { ManufacturingComponent } from './components/pages/manufacturing/manufacturing.component';
import { CoopeComponent } from './components/pages/coope/coope.component';
import { EduComponent } from './components/pages/edu/edu.component';
import { AgriComponent } from './components/pages/agri/agri.component';
import { CivilSupplyComponent } from './components/pages/civil-supply/civil-supply.component';
import { CareerComponent } from './components/pages/career/career.component';
import { IcccComponent } from './components/pages/iccc/iccc.component';
import { AhmsComponent } from './components/pages/ahms/ahms.component';
import { HrmsComponent } from './components/pages/hrms/hrms.component';
import { VtsComponent } from './components/pages/vts/vts.component';
import { LimsComponent } from './components/pages/lims/lims.component';
import { UtilityProjectComponent } from './components/pages/utility-project/utility-project.component';
import { EgovDeptComponent } from './components/pages/egov-dept/egov-dept.component';
import { ApplyCvComponent } from './components/pages/apply-cv/apply-cv.component';
import { EducationErpComponent } from './components/pages/education-erp/education-erp.component';

const routes: Routes = [
    {path: 'home-one', component: HomeOneComponent},
    {path: 'home-two', component: HomeTwoComponent},
    {path: '', component: HomeThreeComponent},
    {path: 'about', component: AboutPageComponent},
    {path: 'solutions', component: SolutionsPageComponent},
    {path: 'solutions-details', component: SolutionsDetailsPageComponent},
    {path: 'contact', component: ContactPageComponent},
    {path: 'team', component: TeamPageComponent},
    {path: 'pricing', component: PricingPageComponent},
    {path: 'coming-soon', component: ComingSoonPageComponent},
    {path: 'faq', component: FaqPageComponent},
    {path: 'profile-authentication', component: ProfileAuthenticationPageComponent},
    {path: 'blog-grid', component: BlogGridPageComponent},
    {path: 'blog-right-sidebar', component: BlogRightSidebarPageComponent},
    {path: 'products', component: ProductsPageComponent},
    {path: 'cart', component: CartPageComponent},
    {path: 'checkout', component: CheckoutPageComponent},
    {path: 'products-details', component: ProductsDetailsPageComponent},
    {path: 'terms-conditions', component: TermsConditionsPageComponent},
    {path: 'privacy-policy', component: PrivacyPolicyPageComponent},
    {path: 'services-style-one', component: ServicesPageStyleOneComponent},
    {path: 'services-style-two', component: ServicesPageStyleTwoComponent},
    {path: 'services-style-three', component: ServicesPageStyleThreeComponent},
    {path: 'services-details', component: ServicesDetailsPageComponent},
    {path: 'enterprise-soln', component: EnterpriseSolnComponent},
    {path: 'iot', component: IotComponent},
    {path: 'marketing-soln', component: MarketingSolnComponent},
    {path: 'ml-ai', component: MlAiComponent},
    {path: 'smart-card', component: SmartCardDetailsComponent},
    {path: 'smart-city', component: SmartCityComponent},
    {path: 'video-surv', component: VideoSurvSysComponent},
    {path: 'vr-ar', component: VrArComponent},
    {path: 'web-app', component: WebAppComponent},
    {path: 'work', component: WorkComponent},
    {path: 'police', component: PoliceComponent},
    {path: 'transport', component: TransportComponent},
    {path: 'eb', component: EbComponent},
    {path: 'manufacturing', component: ManufacturingComponent},
    {path: 'coope', component: CoopeComponent},
    {path: 'edu', component: EduComponent},
    {path: 'agri', component: AgriComponent},
    {path: 'career', component: CareerComponent},
    {path: 'iccc', component: IcccComponent},
    //product
    {path: 'ahms', component: AhmsComponent},
    {path: 'hrms', component: HrmsComponent},
    {path: 'vts', component: VtsComponent},
    {path: 'lims', component: LimsComponent},
    {path: 'utility', component: UtilityProjectComponent},
    {path: 'egov', component: EgovDeptComponent},
    {path: 'civil-supply', component: CivilSupplyComponent},
    {path: 'cv', component: ApplyCvComponent},
    {path: 'edu-erp', component: EducationErpComponent},
    // Here add new pages component

    {path: '**', component: NotFoundComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }