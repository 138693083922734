<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2 class="font-family005">Products</h2>
            <ul class="font-family005">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Products</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Safety Area -->
<section class="safety-area py-5">
    <div class="container">
        <div class="section-title">
            <span class="font-family004">GET EVERYTHING AUTOMATED AND</span>
            <h2 class="font-family004">Manage your Business with a Better Software</h2>
        </div>


        <app-homeone-services></app-homeone-services>

        <!-- <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-1.png" alt="Image">
                        <a routerLink="/services-details"><h3>Asset Management System</h3></a>
                        <p>
                            Asset management software is a dedicated application which is used to record and track an asset throughout its life cycle, from procurement to disposal. It provides an organization with information like where certain assets are located, who is using them, how they are being utilized and details about the asset. The asset management software is used for management of both software and hardware assets.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-2.png" alt="Image">
                        <a routerLink="/services-details"><h3>Human Resource Management System </h3></a>
                        <p>
                            Human Resources Management System (HRMS) refers to a suite of software that organizations use to manage internal HR functions. From employee data management to payroll, recruitment, benefits, training, talent management, employee engagement, and employee attendance, HRMS software helps HR professionals manage the modern workforce. HRMS systems put information about a company’s most valuable assets in front of the people who need them.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-3.png" alt="Image">
                        <a routerLink="/services-details"><h3>Enterprise Resource Planning </h3></a>
                        <p>
                            Enterprise resource planning (ERP) is a platform companies use to manage and integrate the essential parts of their businesses. Many ERP software applications are critical to companies because they help them implement resource planning by integrating all the processes needed to run their companies with a single system. An ERP software system can also integrate planning, purchasing inventory, sales, marketing, finance, human resources, and more.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-4.png" alt="Image">
                        <a routerLink="/services-details"><h3>Help Desk Management SystemHelp Desk Management System</h3></a>
                        <p>
                            A help desk management system is an application that helps organizations manage and track customer service requests. Its purpose is to streamline the process of tracking and resolving customer service issues, from initial contact to resolution. The help desk management system typically includes a ticketing system, which enables customer service reps to track and manage service requests. It can also track customer service requests from multiple channels, such as phone, email, web, and chat. And can also be used to track and manage service requests from internal employees.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-5.png" alt="Image">
                        <a routerLink="/services-details"><h3>Fleet Management System </h3></a>
                        <p>
                            Fleet management is an administrative approach that allows companies to organize and coordinate work vehicles with the aim to improve efficiency, reduce costs, and provide compliance with government regulations. While most commonly used for vehicle tracking, fleet management includes following and recording mechanical diagnostics and driver behavior.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-safety overly-one">
                    <div class="overly-two">
                        <img src="assets/img/safety-icon/icon-6.png" alt="Image">
                        <a routerLink="/services-details"><h3>Supply Chain Management System </h3></a>
                        <p>
                            A supply chain consists of a network of organizations and facilities that work in tandem to transform raw materials into finished, customer-ready products. A supply chain management (SCM) system is an inter-organizational solution that manages these activities from beginning to end. These SCM systems benefit companies by managing a variety of supply chain processes, such as Procurement, Planning, Product or service creation, Order fulfillment, Order tracking, Order management.</p>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</section>
<!-- End Safety Area -->