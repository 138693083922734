import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homeone-blog',
  templateUrl: './homeone-blog.component.html',
  styleUrls: ['./homeone-blog.component.scss']
})
export class HomeoneBlogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
