<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content pt-0">
            <h2 class="">Solutions</h2>
            <ul class="">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Solutions / Internet of Things</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="solution-area pt-5 pb-70">
    <div class="container px-md-5">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="solution-content">
                    <div class="solution-title">
                        <h2 class="text-capitalize">IOT Solutions</h2>
                        <p class="">An IoT Solution is a seamlessly integrated bundle of technologies, including many sensors, that organizations can purchase to solve an organizational problem and/or create new organizational value.</p>
                        <!-- <h3 class="mb-0">Benefits Of Custom ERP Software</h3> -->
                        <p class="">
                            IoT Solutions helps organizations reduce cost through improved process efficiency, asset utilization and productivity. With improved tracking of devices/objects using sensors and connectivity, businesses can benefit from real-time insights and analytics, which would help them making smarter decisions. IoT has benefited almost every sector, allowing for the development of new businesses and increased productivity.
                        </p> 
                        <h3>Top five advantages of the IoT: </h3>
                        <ul>
                            <li>Reduced costs</li>
                            <li>Higher efficiency and productivity</li>
                                <li> More business opportunities</li>
                                    <li>Improved customer experience</li>
                                        <li>Increased mobility and agility </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 pr-0">
                <div class="solution-img">
                    <img src="assets/img/services/iot.png" alt="Image" class="circle-5">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->

