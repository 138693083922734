import { Component } from '@angular/core';

@Component({
  selector: 'app-ahms',
  templateUrl: './ahms.component.html',
  styleUrls: ['./ahms.component.scss']
})
export class AhmsComponent {

}
