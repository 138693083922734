<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog Grid</h2>
            <ul>
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Blog Grid</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-posts">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog/blog-4.jpg" alt="Image">
                    </a>
                    <div class="single-blog-content">
                        <span>Security</span>
                        <h3><a routerLink="/blog-details">The essentials of cybersecurity solutions</a></h3>
                        <p>Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum.</p>
                        <ul class="admin">
                            <li><a routerLink="/blog-grid"><i class="bx bx-user-circle"></i> Carl Bradshaw</a></li>
                            <li class="float"><i class="bx bx-calendar-alt"></i> Sep 20, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-posts">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog/blog-5.jpg" alt="Image">
                    </a>
                    <div class="single-blog-content">
                        <span>Security</span>
                        <h3><a routerLink="/blog-details">Top 10 cybersecurity tips for working place</a></h3>
                        <p>Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum.</p>
                        <ul class="admin">
                            <li><a routerLink="/blog-grid"><i class="bx bx-user-circle"></i> William Gordon</a></li>
                            <li class="float"><i class="bx bx-calendar-alt"></i> Sep 19, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-posts">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog/blog-6.jpg" alt="Image">
                    </a>
                    <div class="single-blog-content">
                        <span>Security</span>
                        <h3><a routerLink="/blog-details">Cloud network detection and response</a></h3>
                        <p>Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum.</p>
                        <ul class="admin">
                            <li><a routerLink="/blog-grid"><i class="bx bx-user-circle"></i> Brian Eortiz</a></li>
                            <li class="float"><i class="bx bx-calendar-alt"></i> Sep 18, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-posts">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog/blog-7.jpg" alt="Image">
                    </a>
                    <div class="single-blog-content">
                        <span>Security</span>
                        <h3><a routerLink="/blog-details">Don’t forget cybersecurity on your computer</a></h3>
                        <p>Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum.</p>
                        <ul class="admin">
                            <li><a routerLink="/blog-grid"><i class="bx bx-user-circle"></i> Daniel Brady</a></li>
                            <li class="float"><i class="bx bx-calendar-alt"></i> Sep 17, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-posts">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog/blog-8.jpg" alt="Image">
                    </a>
                    <div class="single-blog-content">
                        <span>Security</span>
                        <h3><a routerLink="/blog-details">Top 5 cybersecurity threats in 2020</a></h3>
                        <p>Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum.</p>
                        <ul class="admin">
                            <li><a routerLink="/blog-grid"><i class="bx bx-user-circle"></i> Bradshaw</a></li>
                            <li class="float"><i class="bx bx-calendar-alt"></i> Sep 16, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-posts">
                    <a routerLink="/blog-details">
                        <img src="assets/img/blog/blog-9.jpg" alt="Image">
                    </a>
                    <div class="single-blog-content">
                        <span>Security</span>
                        <h3><a routerLink="/blog-details">The essentials of cybersecurity solutions</a></h3>
                        <p>Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum.</p>
                        <ul class="admin">
                            <li><a routerLink="/blog-grid"><i class="bx bx-user-circle"></i> Carl Bradshaw</a></li>
                            <li class="float"><i class="bx bx-calendar-alt"></i> Sep 15, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="pagination-area">
                    <span class="page-numbers current">1</span>
                    <a routerLink="/blog-grid" class="page-numbers">2</a>
                    <a routerLink="/blog-grid" class="page-numbers">3</a>
                    <a routerLink="/blog-grid" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->