import { Component , OnInit} from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-smart-card-details',
  templateUrl: './smart-card-details.component.html',
  styleUrls: ['./smart-card-details.component.scss']
})
export class SmartCardDetailsComponent {

  constructor() { }

  ngOnInit(): void {
  }
  
  relatedSlides: OwlOptions = {
  loop: true,
  margin: 30,
  nav: false,
  dots: false,
  autoplay: true,
  smartSpeed: 1000,
  autoplayHoverPause: true,
  responsive:{
    0:{
      items: 1,
    },
    576:{
      items: 2,
    },
    768:{
      items: 3,
    },
    1200:{
      items: 3,
    }
  }
  }

}
