<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="font-family004">Latest News</span>
            <h2 class="font-family005">Read the Latest Articles from Us</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a>
                            <img src="assets/img/blog/blog-4.jpg" alt="Image" class="img-thumbnail">
                        </a>
                    </div>
                    <div class="blog-content">
                        <span class="font-family005">October 16, 2022</span>
                        <h3 class="font-family003"><a target="_blank" href="https://www.thehindu.com/news/cities/chennai/tamil-nadu-police-to-roll-out-new-electronic-beat-system-using-smart-kavalar-app/article66107709.ece">Tamil Nadu police to roll out new electronic beat system using SmartKavalar app</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a>
                            <img src="assets/img/blog/blog-5.jpg" alt="Image" class="img-thumbnail">
                        </a>
                    </div>
                    <div class="blog-content">
                        <span class="font-family005">Jan 03, 2023</span>
                        <h3 class="font-family003"><a target="_blank" href="https://www.thehindu.com/news/cities/Madurai/app-based-investigation-helps-in-speedy-crime-detection-says-thoothukudi-sp/article66333664.ece">Smart Kavalar App  helps in speedy crime detection; says Thoothukudi SP </a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog">
                    <div class="blog-img">
                        <a>
                            <img src="assets/img/blog/blog-7.png" alt="Image" class="img-thumbnail">
                        </a>
                    </div>
                    <div class="blog-content">
                        <span class="font-family005">Feb 23, 2019</span>
                        <h3 class="font-family003"><a target="_blank" href="https://www.thehindu.com/news/cities/Coimbatore/transport-dept-launches-smart-cards/article26348270.ece">Transport Department has launched the smart card facility with added security features</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>