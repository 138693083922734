<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2 class="font-family005">Civil Supply</h2>
            <ul class="font-family005">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">TNCSC CCTV & GPS</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<div class="blog-details-area py-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col12 col-md-8">
                <div class="blog-details-content">
                    <div class="blog-top-content">
                        <div class="news-content font-family004">
                            <h3 class="font-family005 mb-2">TNCSC CCTV</h3>
                            <p>An Internet Protocol camera, or IP camera, is a type of digital video camera that receives control data and sends image data via the Internet. They are commonly used for surveillance. Unlike analog closed-circuit television (CCTV) cameras, they require no local recording device, but only a local area network. Most IP cameras are webcams, but the term IP camera or netcam usually applies only to those used for surveillance that can be directly accessed over a network connection.</p>
                        </div>
                        <div class="news-content font-family004 mt-3">
                            <p>“The main functions of Corporation are to procure essential commodities like rice, sugar, wheat, etc., storage and movement of such stocks for its distribution from the Taluk operational godowns to various public distribution system outlets through the Co-operative Societies and other agencies. The Corporation also under takes procurement of paddy, hulling, and utilization of rice under decentralized procurement system.”</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="row">
                    <div class="col-12 mb-2">
                        <div class="single-blog-post-img">
                            <img src="assets/img/services/cctv.jpg" alt="Image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12 col-md-4">
                <div class="row">
                    <div class="col-12 mb-2">
                        <div class="single-blog-post-img">
                            <img src="assets/img/services/gps.jpg" alt="Image">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col12 col-md-8">
                <div class="blog-details-content">
                    <div class="blog-top-content">
                        <div class="news-content font-family004">
                            <h3 class="font-family005 mb-2">TNCSC GPS</h3>
                            <p>VTS allows you to view your entire fleet in single window real time. You can track your asset’s movement live, set Geo-fencing for alerts, check for over speed in case of fragile cargo movement, unscheduled overstays, fuel reports etc. It helps you in setting up customized dashboard for your entire fleet showing you the numbers you need- assets on active movement, idle assets, number of assets having planned movement for the day/ week, amount of pilferage asset wise as per their movement etc.</p>
                        </div>
                        <div class="news-content font-family004 mt-3">
                            <p class="text-warning">“BonTon is a leader in GPS and mobile tracking system by enabling APP-to-APP and APP-TO-WEB Mobility solutions and platform to power the Internet of Things (IoT).”</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Blog Details Area -->