<!-- <section class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Cyber Security Services</span>
            <h2>You Can Protect Your Organization’s Cybersecurity By Services Us</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-1.jpg" alt="Image">
                        </a>
                    </div>
                    <div class="services-content">
                        <h3><a routerLink="/services-details">Website Scanning</a></h3>
                        <div class="content">
                            <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p>
                            <a routerLink="/services-details" class="read-more">Read More <i class="flaticon-right-arrow"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-2.jpg" alt="Image">
                        </a>
                    </div>
                    <div class="services-content">
                        <h3><a routerLink="/services-details">Malware Removal</a></h3>
                        <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p>
                        <a routerLink="/services-details" class="read-more">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-3.jpg" alt="Image">
                        </a>
                    </div>
                    <div class="services-content">
                        <h3><a routerLink="/services-details">Cloud Security</a></h3>
                        <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p>
                        <a routerLink="/services-details" class="read-more">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-services">
                    <div class="services-img">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-4.jpg" alt="Image">
                        </a>
                    </div>
                    <div class="services-content">
                        <h3><a routerLink="/services-details">Data Protection</a></h3>
                        <p>Lorem ipsum dolor sit amet, con sectetur adipiscing elit sed do.</p>
                        <a routerLink="/services-details" class="read-more">Read More <i class="flaticon-right-arrow"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
<section class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title ffamily06">
            <h2 class="color11 ffamily03">THE FUTURE IS ALREADY HERE</h2>
            <h4 class="textPrimary">We Provide Complete Set Of Custom-made Services</h4>
        </div>
        <div class="px-lg-5 mx-lg-5 px-2">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="box p-3 border-shape">
                        <p class="leftPara">
                            Let’s take a look at just one of the applications that would be easily adopted once the technology is rolled out to a more efficient, affordable and comfortable technological stage. <br/><br/>
                            Once whole worlds can be simulated for the senses, the only way to assure the integrity of the public imagination will be to get the power to create those worlds out of the hands of an elite and into general circulation.
                        </p>
                    </div>
                </div>
    
                <div class="col-12 col-md-6">
                    <div class="box p-3 bg06 border-shape bsha">
                        <div class="single-services rounded">
                            <div class="services-img simg">
                                <a routerLink="/services-details">
                                    <img src="assets/img/approach-img.jpg" alt="Testing" class="img-fluid rounded polygonal-img"
                                    style="height: 350px;">
                                </a>
                            </div>
                            <!-- <div class="services-content ffamily01">
                                <h3 class="text-center" style="margin-left: -100px;"><a routerLink="/services-details">FUTURE</a></h3>
                                <p style="width: 80%;">The most fantastic thing about the present time is that We're actually still here.</p>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>