import { Component , OnInit} from '@angular/core';

@Component({
  selector: 'app-video-surv-sys',
  templateUrl: './video-surv-sys.component.html',
  styleUrls: ['./video-surv-sys.component.scss']
})
export class VideoSurvSysComponent {


  constructor() { }

  ngOnInit(): void {
  }

}
