<section class="banner-area banner-area-two bg-2">
    <div class="container">
        <div class="banner-content">
            <div class="banner_img_wrapper d-none d-md-block overflow-hidden top-0 end-0 h-100"><div class="diagonal_img bg-light w-100 h-100" style="background-image: url(&quot;https://cdn.vuram.com/0848f835da75f6bb5b4e75c00c4c1c4d.jpg&quot;);"></div></div>
            <!-- <span class="top-title">We Pursue Idea</span> --><span class="text-001"></span>
            <h1 class="font-family002 space02">Where Innovation meets Excellence<br/>Bon<span class="text-001 font-weight6">T</span>on is all about uncompromising excellence.</h1>
            <p class="font-family003">We are here to change the game and add real value with technology at its best – <br/>Technology that improves our customers way of functioning.</p>
            <a routerLink="/about" class="default-btn"><span>About Us</span></a>
        </div>
    </div>
</section>