<div class="container">
    <div class="partner-slider text-center">
        <div class="partner-title">
            <h2>Our Partners</h2>
        </div>
        <owl-carousel-o [options]="partnerSlides">
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#">
                        <img src="assets/img/partner/aws.jpg" alt="Image">
                    </a>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#">
                        <img src="assets/img/partner/google.jpg" alt="Image">
                    </a>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#">
                        <img src="assets/img/partner/partner-4.png" alt="Image">
                    </a>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#">
                        <img src="assets/img/partner/microsoft.jpg" alt="Image">
                    </a>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="partner-item">
                    <a href="#">
                        <img src="assets/img/partner/partner-5.png" alt="Image">
                    </a>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>