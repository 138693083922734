import { Component } from '@angular/core';

@Component({
  selector: 'app-utility-project',
  templateUrl: './utility-project.component.html',
  styleUrls: ['./utility-project.component.scss']
})
export class UtilityProjectComponent {

}
