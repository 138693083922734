import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ml-ai',
  templateUrl: './ml-ai.component.html',
  styleUrls: ['./ml-ai.component.scss']
})
export class MlAiComponent {
  constructor() { }

  ngOnInit(): void {
  }


}
