<section class="our-approach-area-three pt-5 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Transforming business value chains through end-to-end services</h2>
            <span class="font-018">As organizations increasingly use technology to transform their business value chains, our end-to-end digital services and solutions help them accelerate results.</span>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-approach-box overly-one">
                    <div class="overly-two">
                        <div class="icon">
                            <img src="assets/img/services/future.png" alt="Image">
                        </div>
                        <h3>Future</h3>
                        <p>Build your future with Idea`s</p>
                        <div class="approach-shape"><img src="assets/img/approach-shape.png" alt="Image"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-approach-box overly-one">
                    <div class="overly-two">
                        <div class="icon">
                            <img src="assets/img/services/agility.png" alt="Image">
                        </div>
                        <h3>Agility & Speed</h3>
                        <p>Execute with agility and speed</p>
                        <div class="approach-shape"><img src="assets/img/approach-shape.png" alt="Image"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-approach-box overly-one">
                    <div class="overly-two">
                        <div class="icon">
                            <img src="assets/img/services/efficiency.png" alt="Image">
                        </div>
                        <h3>Quality & Efficiency</h3>
                        <p>Operate with quality & efficiency</p>
                        <div class="approach-shape"><img src="assets/img/approach-shape.png" alt="Image"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-approach-box overly-one">
                    <div class="overly-two">
                        <div class="icon">
                            <img src="assets/img/services/decision.png" alt="Image">
                        </div>
                        <h3>Decision</h3>
                        <p>Stay committed to your Decisions</p>
                        <div class="approach-shape"><img src="assets/img/approach-shape.png" alt="Image"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>