import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-apply-cv',
  templateUrl: './apply-cv.component.html',
  styleUrls: ['./apply-cv.component.scss']
})
export class ApplyCvComponent {
  FormData: FormGroup;
    submitted = false;

    constructor(private http: HttpClient, private formBuilder: FormBuilder, private router: Router) { }

    ngOnInit(): void {
        this.FormData = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            subject: ['', Validators.required],
            message: ['', Validators.required]
        });
    }
    successNotification() {
        Swal.fire('Mail has been sent', 'Our team will contact you shortly', 'success');
    }
    errorNotification() {
        Swal.fire('Mail Delivery Failed', 'Please try again or some time', 'error');
    }
    get f() { return this.FormData.controls; }
    onSubmit() {
        this.submitted = true;
        if (this.FormData.invalid) {
           //console.log("form not valid");
           return;
        } else {
            // Initialize Params Object
            var myFormData = new FormData();
            const headers = new HttpHeaders();
            // Begin assigning parameters
            myFormData.append('name', this.FormData.value.name);
            myFormData.append('email', this.FormData.value.email);
            myFormData.append('subject', this.FormData.value.subject);
            myFormData.append('message', this.FormData.value.message);

            return this.http.post('https://vividtranstech.com/bs/mail.php?send_mail', myFormData).subscribe((res: any) => {
                console.log("User Registration has been done.", res);
                // const resp = res.result
                if(res.result == 'success'){
                    this.successNotification();
                    this.onReset();
                }else {
                    this.errorNotification();
                }
            });
        }
    }

    onReset() {
        this.submitted = false;
        this.FormData.reset();
    }
}
