<section class="about-us-area pt-5 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="about-img">
                    <img src="assets/img/abt1.jpg" alt="Image" class="img-thumbnail" style="height: 475px;width: 575px;">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="about-title">
                        <h5 class="mb-1 font-family005">About Us</h5>
                        <p class="font-family004">
                            BonTon Softwares is committed to becoming one of India’s fastest growing and most diversified business organizations, admired across peers, partners and customers for its people, partnerships and performance. It aims to be a superior investment for all the stakeholders, delivering innovative & sustainable solutions to help them achieve the best business results.
                        </p>
                    </div>
                    <div class="tab font-family005">
                        <ngx-tabset>
                            <ngx-tab tabTitle="Our Experience">
                                <p class="font-family004">BonTon is all about uncompromising excellence. We believe in success through success. Winning our patron's win is our style of business.</p>
                                <ul class="font-family004">
                                    <li><i class="bx bx-check-circle"></i> Top-of-the-class in Performance and Talent</li>
                                    <li><i class="bx bx-check-circle"></i> Most valued ally for all our partners and customers</li>
                                    <li><i class="bx bx-check-circle"></i> First with the latest technology solutions</li>
                                    <li><i class="bx bx-check-circle"></i> Guarantor of best business results</li>
                                </ul>
                            </ngx-tab>
                            <ngx-tab tabTitle="Why Us?">
                                <p class="font-family004">That precisely is the reason we groom a special research team within our firm in search for better solutions ever. Started by engineers turned entrepreneurs to change the fate of IT services industry today, we offer a world-class service driven solely by our patron's success, which is your success. Yes, we do not serve clients. We win patrons.</p>
                                <ul class="font-family004">
                                    <li><i class="bx bx-check-circle"></i> First with the latest technology solutions</li>
                                    <li><i class="bx bx-check-circle"></i> Guarantor of best business results</li>
                                    <li><i class="bx bx-check-circle"></i> Top-of-the-class in Performance and Talent</li>
                                    <li><i class="bx bx-check-circle"></i> First with the latest technology solutions</li>
                                </ul>
                            </ngx-tab>
                            <ngx-tab tabTitle="Our Approach">
                                <p class="font-family004">Our service does not start and end with contracts. Our service starts with shouldering our patron's business by fulfilling their informational needs and our success is defined by their achievement of targets. We are not satisfied with mere customer satisfaction; we win our business by delivering excellent solutions and finest of services.</p>
                                <ul class="font-family004">
                                    <li><i class="bx bx-check-circle"></i> Top-of-the-class in Performance and Talent</li>
                                    <li><i class="bx bx-check-circle"></i> Guarantor of best business results</li>
                                    <li><i class="bx bx-check-circle"></i> Guarantor of best business results</li>
                                    <li><i class="bx bx-check-circle"></i> First with the latest technology solutions</li>
                                </ul>
                            </ngx-tab>
                        </ngx-tabset>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>