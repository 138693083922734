import { Component } from '@angular/core';

@Component({
  selector: 'app-agri',
  templateUrl: './agri.component.html',
  styleUrls: ['./agri.component.scss']
})
export class AgriComponent {

}
