import { Component , OnInit} from '@angular/core';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss']
})
export class WorkComponent {

  constructor() { }

  ngOnInit(): void {
  }

  classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

}
