<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content pt-0">
            <h2 class="">Products</h2>
            <ul class="">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Products / LIMS</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-7">
                <div class="services-details-content">
                    <div class="services-content">
                        <h2 class="text-c02">Lab Inventory Management System</h2>
                        <p>Lab inventory management solutions enable laboratories and scientific research institutions to streamline overall lab management; by efficiently handling lab inventory tasks, the entire lab benefits from reduced costs and increased productivity. With lab inventory management software, labs can more effectively monitor inventory, assess stock levels, forecast stock reordering, and eliminate inaccuracies when keeping track of resources. These solutions facilitate everything from simple labeling and documentation to maintaining auditable records and databases, as well as purchasing and scheduling</p>
                        <p>As a manager handling a lab's work, equipment, employees and supplies, you're a go-to person for these kinds of everyday struggles. To make your job easier and help you with documentation and stock management, I have summed up best practices for laboratory inventory management.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5">
                <div class="d-flex align-items-center justify-content-center p-md-5">
                    <img src="assets/img/product/lims.png" alt="" class="">
                </div>
            </div>
        </div>
    </div>
</section>
<section class="services-details-area py-5 bg-grey-light">
    <div class="container">
        <div class="row mt-4">
            <div class="col-12 col-md-6 text-center p-2 px-md-5">
                <img src="assets/img/product/lims2.svg" alt="" class="">
            </div>
            <div class="col-12 col-md-6">
                <div class="services-details-content">
                    <div class="services-content">
                        <h2 class="text-c02">Qualify for inclusion in the LIMS</h2>
                        <ul style="list-style: square;">
                            <li>Monitor and track inventory, stock, equipment, and/or other types of laboratory resources</li>
                            <li>Generate inventory records on a lab-determined basis</li>
                            <li>Support data input or import from Excel or third-party data systems</li>
                            <li>Laboratory Information Management System (LIMS) is software that allows you to effectively manage samples and associated data</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->

