<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2 class="font-family005">Co-operative Society</h2>
            <ul class="font-family005">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Co-operative</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<div class="blog-details-area py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-8">
                <div class="blog-details-content">
                    <div class="blog-top-content">
                        <div class="news-content font-family004">
                            <h3 class="font-family005 mb-2">CO-OPERATIVE</h3>
                            <p>In software development process, cooperation needs coordination processes to harmonize tasks among development team members. In turn, coordination needs corresponding communication mechanism to exchange information among the teammates.
                            Therefore, cooperation should be achieved on the basis of coordination and communication.
                            Cooperation is the manner of coordination that is necessary for agreeing on common goals and for the coordinated achievement of common work results among the
                            participants
                            </p>
                        </div>
                        <div class="news-content font-family004 mt-3">
                            <p>Cooperatives are people-centred enterprises owned, controlled and run by and for their members to realise their common economic, social, and cultural needs and aspirations. Cooperatives bring people together in a democratic and equal way.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="row">
                    <div class="col-12">
                        <div class="single-blog-post-img">
                            <a>
                                <img src="assets/img/product/coop.jpeg" alt="Image">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-12 col-md-12">
                <div class="blog-details-content">
                    <div class="blog-top-content">
                        <div class="news-content font-family004 mt-3">
                            <h3 class="font-family005 mb-2">WHAT WE DO FOR THE PROJECT?</h3>
                            <p>We are using Angular Technology here. Angular is a platform and framework for building single-page client applications using HTML and TypeScript. Angular is written in TypeScript. It implements core and optional functionality as a set of TypeScript libraries that you import into your applications</p>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>
<!-- End Blog Details Area -->