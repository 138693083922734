<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content pt-0">
            <h2 class="">Join us</h2>
            <ul class="">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Join us</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Contact Area -->
<section class="main-contact-area py-5">
    <div class="container px-md-5">
        <div class="row align-items-center">
            <div class="col-lg-8 col-md-12 bg-grey-light">
                <div class="contact-wrap">
                    <div class="contact-form">
                        <div class="contact-title">
                            <h2 class="">Submit your application. Our team will contact you shortly</h2>
                        </div>
                        <form [formGroup]="FormData" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <label class="font-family004">Name</label>
                                        <input class="form-control" name="name" placeholder="Full Name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                                        <div *ngIf="submitted && f.name.errors">
                                            <small *ngIf="f.name.errors.required" class="form-text text-danger">Name is required</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="form-group">
                                        <label class="font-family004">Email Address</label>
                                        <input type="text" name="email" placeholder="Enter email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                        <div *ngIf="submitted && f.email.errors" class="form-text text-danger">
                                            <small *ngIf="f.email.errors.required">Email is required</small>
                                            <small *ngIf="f.email.errors.email">Email must be a valid email address</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label class="font-family004">Experience</label>
                                        <input name="subject" placeholder="Enter Experience" formControlName="subject" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.subject.errors }">
                                        <div *ngIf="submitted && f.subject.errors">
                                            <small *ngIf="f.subject.errors.required" class="form-text text-danger">Experience is required</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label class="font-family004">Upload Resume</label>
                                        <input type="file" formControlName="message" class="form-control" cols="30" rows="10" [ngClass]="{ 'is-invalid': submitted && f.message.errors }">
                                        
                                        <div *ngIf="submitted && f.message.errors">
                                            <small *ngIf="f.message.errors.required" class="form-text text-danger">Resume is required</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn btn-two"><span>Send Resume</span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="contact-info">
                    <h3 class="">Our contact details</h3>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p> -->

                    <ul class="address font-family004">
                        <li class="location">
                            <i class="bx bxs-location-plus"></i>
                            <span>Address</span>
                            #82,Santhome High Road
                            5th Floor, High Gates
                            MRC Nagar,
                            chennai-28.
                        </li>
                        <li>
                            <i class="bx bxs-phone-call"></i>
                            <span>Phone</span>
                            <a href="tel:+15143125678">044 24612769</a>
                            <!-- <a href="tel:+15143126677">+1 (514) 312-6677</a> -->
                        </li>
                        <li>
                            <i class="bx bxs-envelope"></i>
                            <span>Email</span>
                            <a href="mailto:info@bontonsoftwares.com">info@bontonsoftwares.com
                        </a>
                            <!-- <a href="#">skype: example</a> -->
                        </li>
                    </ul>

                    <div class="sidebar-follow-us">
                        <h3 class="">Follow us:</h3>
                        <ul class="social-wrap">
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
