import { Component , OnInit} from '@angular/core';

@Component({
  selector: 'app-smart-city',
  templateUrl: './smart-city.component.html',
  styleUrls: ['./smart-city.component.scss']
})
export class SmartCityComponent {
  constructor() { }

  ngOnInit(): void {
  }


}
