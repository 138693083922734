<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2 class="font-family005">Manufacturing</h2>
            <ul class="font-family005">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Manufacturing</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<div class="blog-details-area py-5">
    <div class="container">
        <div class="row match-height">
            <div class="col-12 col-md-8">
                <div class="blog-details-content">
                    <div class="blog-top-content">
                        <div class="news-content font-family004">
                            <h3 class="font-family005 mb-2">TEMPERATURE CHECK AND FACIAL RECOGNITION</h3>
                            <p>(...........) is a 100% subsidiary of YMC and functions as the regional headquarters and corporate control body of India business operations for YMC. (..........) is responsible for Corporate Planning & Strategy, Business Planning & Business Expansion and Quality & Compliance Assurance of Yamaha India Business.
                            </p>
                        </div>
                        <div class="news-content font-family004 mt-3">
                            <h3 class="font-family005 mb-2">Asset Monitoring Mobile App</h3>
                            <p>Vehicles Pvt. Ltd. is a subsidiary of the German Truck AG. The company designs, manufactures, and sells commercial vehicles that cater to the demands of Indian customers. The production plant at Oragadam near Chennai, spreads over 400 acres was inaugurated on 18 April 2012. The plant hosts a full production facility including body shop, paint and assembly as well as all logistics needed.</p>
                        </div>
                        <div class="news-content font-family004 mt-3">
                            <h3 class="font-family005 mb-2">Project Tracking Web App</h3>
                            <p>Chennai Metropolitan Water Supply and Sewerage Board, known shortly as CMWSSB, is a statutory board of Government of Tamil Nadu which provides water supply and sewage treatment to the city of Chennai and areas around it. As of 2012, Chennai Metrowater supplies about 830 million litres of water every day to residents and commercial establishments</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="row">
                    <div class="col-12">
                        <div class="single-blog-post-img">
                            <a>
                                <img src="assets/img/product/progress.png" alt="Image" class="img-fluid">
                            </a>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="single-blog-post-img">
                            <a>
                                <img src="assets/img/product/pppp.png" alt="Image" class="img-fluid">
                            </a>
                        </div>
                    </div>
                     <!-- <div class="col-6">
                        <div class="single-blog-post-img">
                            <a>
                                <img src="assets/img/product/dep4.jpg" alt="Image" class="img-fluid">
                            </a>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="single-blog-post-img">
                            <a>
                                <img src="assets/img/product/dep3.jpg" alt="Image" class="img-fluid">
                            </a>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Blog Details Area -->