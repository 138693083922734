import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hometwo-pricing',
  templateUrl: './hometwo-pricing.component.html',
  styleUrls: ['./hometwo-pricing.component.scss']
})
export class HometwoPricingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
