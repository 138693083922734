<app-navbar-style-two></app-navbar-style-two>

<app-hometwo-banner></app-hometwo-banner>

<app-hometwo-features></app-hometwo-features>

<app-our-challenges></app-our-challenges>

<app-hometwo-approach></app-hometwo-approach>

<!-- <app-homeone-services></app-homeone-services>  -->
<app-hometwo-services></app-hometwo-services>

<!-- <app-hometwo-pricing></app-hometwo-pricing> -->

<app-funfacts></app-funfacts>

<!-- <section class="team-area pt-100 pb-70">
    <app-team></app-team>
</section> -->

<app-hometwo-blog></app-hometwo-blog>

<app-get-in-touch></app-get-in-touch>

