import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-tabset';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { LightboxModule } from 'ngx-lightbox';
import { StickyNavModule } from 'ng2-sticky-nav';
import { AccordionModule } from "ngx-accordion";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeoneBannerComponent } from './components/pages/home-one/homeone-banner/homeone-banner.component';
import { NavbarStyleOneComponent } from './components/common/navbar-style-one/navbar-style-one.component';
import { HometwoBannerComponent } from './components/pages/home-two/hometwo-banner/hometwo-banner.component';
import { NavbarStyleTwoComponent } from './components/common/navbar-style-two/navbar-style-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { HomethreeBannerComponent } from './components/pages/home-three/homethree-banner/homethree-banner.component';
import { NavbarStyleThreeComponent } from './components/common/navbar-style-three/navbar-style-three.component';
import { HometwoFeaturesComponent } from './components/pages/home-two/hometwo-features/hometwo-features.component';
import { HomethreeFeaturesComponent } from './components/pages/home-three/homethree-features/homethree-features.component';
import { HomeoneFeaturesComponent } from './components/pages/home-one/homeone-features/homeone-features.component';
import { HomeoneAboutComponent } from './components/pages/home-one/homeone-about/homeone-about.component';
import { OurChallengesComponent } from './components/common/our-challenges/our-challenges.component';
import { HomeoneServicesComponent } from './components/pages/home-one/homeone-services/homeone-services.component';
// import { HomeoneServices1Component } from './components/pages/home-one/homeone-services1/homeone-services.component1';
import { HomeoneSolutionComponent } from './components/pages/home-one/homeone-solution/homeone-solution.component';
import { GetInTouchComponent } from './components/common/get-in-touch/get-in-touch.component';
import { HomeoneProtectComponent } from './components/pages/home-one/homeone-protect/homeone-protect.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { HomeoneBlogComponent } from './components/pages/home-one/homeone-blog/homeone-blog.component';
import { HometwoApproachComponent } from './components/pages/home-two/hometwo-approach/hometwo-approach.component';
import { HometwoServicesComponent } from './components/pages/home-two/hometwo-services/hometwo-services.component';
import { HometwoPricingComponent } from './components/pages/home-two/hometwo-pricing/hometwo-pricing.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { TeamComponent } from './components/common/team/team.component';
import { HometwoBlogComponent } from './components/pages/home-two/hometwo-blog/hometwo-blog.component';
import { HomethreeBlogComponent } from './components/pages/home-three/homethree-blog/homethree-blog.component';
import { HomethreeFaqComponent } from './components/pages/home-three/homethree-faq/homethree-faq.component';
import { HomethreeProtectComponent } from './components/pages/home-three/homethree-protect/homethree-protect.component';
import { HomethreePricingComponent } from './components/pages/home-three/homethree-pricing/homethree-pricing.component';
import { HomethreeServicesComponent } from './components/pages/home-three/homethree-services/homethree-services.component';
import { HomethreeSolutionComponent } from './components/pages/home-three/homethree-solution/homethree-solution.component';
import { HomethreeApproachComponent } from './components/pages/home-three/homethree-approach/homethree-approach.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { AboutPageComponent } from './components/pages/about-page/about-page.component';
import { AboutpageAboutComponent } from './components/pages/about-page/aboutpage-about/aboutpage-about.component';
import { SolutionsPageComponent } from './components/pages/solutions-page/solutions-page.component';
import { SolutionsDetailsPageComponent } from './components/pages/solutions-details-page/solutions-details-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { TeamPageComponent } from './components/pages/team-page/team-page.component';
import { PricingPageComponent } from './components/pages/pricing-page/pricing-page.component';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { ProfileAuthenticationPageComponent } from './components/pages/profile-authentication-page/profile-authentication-page.component';
import { BlogGridPageComponent } from './components/pages/blog-grid-page/blog-grid-page.component';
import { BlogRightSidebarPageComponent } from './components/pages/blog-right-sidebar-page/blog-right-sidebar-page.component';
// import { BlogDetailsPageComponent } from './components/pages/blog-details-page/blog-details-page.component';
import { ProductsPageComponent } from './components/pages/products-page/products-page.component';
import { CartPageComponent } from './components/pages/cart-page/cart-page.component';
import { CheckoutPageComponent } from './components/pages/checkout-page/checkout-page.component';
import { ProductsDetailsPageComponent } from './components/pages/products-details-page/products-details-page.component';
import { TermsConditionsPageComponent } from './components/pages/terms-conditions-page/terms-conditions-page.component';
import { PrivacyPolicyPageComponent } from './components/pages/privacy-policy-page/privacy-policy-page.component';
import { ServicesPageStyleOneComponent } from './components/pages/services-page-style-one/services-page-style-one.component';
import { ServicesPageStyleTwoComponent } from './components/pages/services-page-style-two/services-page-style-two.component';
import { ServicesPageStyleThreeComponent } from './components/pages/services-page-style-three/services-page-style-three.component';
import { ServicesDetailsPageComponent } from './components/pages/services-details-page/services-details-page.component';
import { EnterpriseSolnComponent } from './components/pages/enterprise-soln/enterprise-soln.component';
import { IotComponent } from './components/pages/iot/iot.component';
import { MarketingSolnComponent } from './components/pages/marketing-soln/marketing-soln.component';
import { MlAiComponent } from './components/pages/ml-ai/ml-ai.component';
import { SmartCardDetailsComponent } from './components/pages/smart-card-details/smart-card-details.component';
import { SmartCityComponent } from './components/pages/smart-city/smart-city.component';
import { VideoSurvSysComponent } from './components/pages/video-surv-sys/video-surv-sys.component';
import { VrArComponent } from './components/pages/vr-ar/vr-ar.component';
import { WebAppComponent } from './components/pages/web-app/web-app.component';
import { WorkComponent } from './components/pages/work/work.component';
import { PoliceComponent } from './components/pages/police/police.component';
import { TransportComponent } from './components/pages/transport/transport.component';
import { EbComponent } from './components/pages/eb/eb.component';
import { ManufacturingComponent } from './components/pages/manufacturing/manufacturing.component';
import { CoopeComponent } from './components/pages/coope/coope.component';
import { EduComponent } from './components/pages/edu/edu.component';
import { AgriComponent } from './components/pages/agri/agri.component';
import { HttpClientModule } from '@angular/common/http';
import { CivilSupplyComponent } from './components/pages/civil-supply/civil-supply.component';
import { CareerComponent } from './components/pages/career/career.component';
import { IcccComponent } from './components/pages/iccc/iccc.component';
import { AhmsComponent } from './components/pages/ahms/ahms.component';
import { HrmsComponent } from './components/pages/hrms/hrms.component';
import { VtsComponent } from './components/pages/vts/vts.component';
import { LimsComponent } from './components/pages/lims/lims.component';
import { UtilityProjectComponent } from './components/pages/utility-project/utility-project.component';
import { EgovDeptComponent } from './components/pages/egov-dept/egov-dept.component';
import { ApplyCvComponent } from './components/pages/apply-cv/apply-cv.component';
import { EducationErpComponent } from './components/pages/education-erp/education-erp.component';


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HomeOneComponent,
    HomeTwoComponent,
    HomeoneBannerComponent,
    NavbarStyleOneComponent,
    HometwoBannerComponent,
    NavbarStyleTwoComponent,
    HomeThreeComponent,
    HomethreeBannerComponent,
    NavbarStyleThreeComponent,
    HometwoFeaturesComponent,
    HomethreeFeaturesComponent,
    HomeoneFeaturesComponent,
    HomeoneAboutComponent,
    OurChallengesComponent,
    HomeoneServicesComponent,
    HomeoneSolutionComponent,
    GetInTouchComponent,
    HomeoneProtectComponent,
    FeedbackComponent,
    PartnerComponent,
    HomeoneBlogComponent,
    HometwoApproachComponent,
    HometwoServicesComponent,
    HometwoPricingComponent,
    FunfactsComponent,
    TeamComponent,
    HometwoBlogComponent,
    HomethreeBlogComponent,
    HomethreeFaqComponent,
    HomethreeProtectComponent,
    HomethreePricingComponent,
    HomethreeServicesComponent,
    HomethreeSolutionComponent,
    HomethreeApproachComponent,
    NotFoundComponent,
    AboutPageComponent,
    AboutpageAboutComponent,
    SolutionsPageComponent,
    SolutionsDetailsPageComponent,
    ContactPageComponent,
    TeamPageComponent,
    PricingPageComponent,
    ComingSoonPageComponent,
    FaqPageComponent,
    ProfileAuthenticationPageComponent,
    BlogGridPageComponent,
    BlogRightSidebarPageComponent,
    // BlogDetailsPageComponent,
    ProductsPageComponent,
    CartPageComponent,
    CheckoutPageComponent,
    ProductsDetailsPageComponent,
    TermsConditionsPageComponent,
    PrivacyPolicyPageComponent,
    ServicesPageStyleOneComponent,
    ServicesPageStyleTwoComponent,
    ServicesPageStyleThreeComponent,
    ServicesDetailsPageComponent,
    SmartCardDetailsComponent,
    SmartCityComponent,
    VideoSurvSysComponent,
    IotComponent,
    VrArComponent,
    MarketingSolnComponent,
    EnterpriseSolnComponent,
    WebAppComponent,
    MlAiComponent,
    WorkComponent,
    PoliceComponent,
    TransportComponent,
    EbComponent,
    ManufacturingComponent,
    CoopeComponent,
    EduComponent,
    AgriComponent,
    CivilSupplyComponent,
    CareerComponent,
    IcccComponent,
    AhmsComponent,
    HrmsComponent,
    VtsComponent,
    LimsComponent,
    UtilityProjectComponent,
    EgovDeptComponent,
    ApplyCvComponent,
    EducationErpComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TabsModule,
    CarouselModule,
    BrowserAnimationsModule,
    LightboxModule,
    NgxScrollTopModule,
    StickyNavModule,
    AccordionModule
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
