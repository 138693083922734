<!-- Start Footer Area -->
<a class="primary-btn sideOpen" routerLink="/contact"><i class="bx bx-cog me-2"></i>Enquiry</a>

<footer class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo.png" alt="Image"></a>
                    <p class="font-family005">BonTon is all about uncompromising excellence. We believe in success through success. Winning our patron's win is our style of business.</p>
                    <ul class="social-icon">
                        <li><a target="_blank" href="#"><i class="bx bxl-facebook"></i></a></li>
                        <li><a target="_blank" href="#"><i class="bx bxl-instagram"></i></a></li>
                        <li><a target="_blank" href="#"><i class="bx bxl-linkedin-square"></i></a></li>
                        <li><a target="_blank" href="#"><i class="bx bxl-twitter"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3 class="font-family004">Address</h3>
                    <ul class="address">
                        <li class="location font-family005">
                            <i class="bx bxs-location-plus"></i>
                            Hight Gates ,5th Floor, MRC Nagar
                            Chennai-600 028
                        </li>
                        <li>
                            <i class="bx bxs-envelope"></i>
                            <a href="mailto:info@bontonsoftwares.com">info@bontonsoftwares.com</a>
                          
                        </li>
                        <li>
                            <i class="bx bxs-phone-call"></i>
                            <a href="tel:044-24612769">044 24612769</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3 class="font-family004">Solutions</h3>
                    <ul class="import-link font-family005">
                        <li><a routerLink="/smart-card" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Smart Card Solutions</a></li>
                        <li><a routerLink="/video-surv" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Video Surveillance Solutions</a></li>
                        <li><a routerLink="/enterprise-soln" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Enterprise Solutions</a></li>
                        <li><a routerLink="/web-app" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Web & Mobile Application</a></li>
                        <li><a routerLink="/iccc" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">ICCC</a></li>
                        <li><a routerLink="/iot" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">IOT</a></li>
                        <li><a routerLink="/marketing-soln" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Digital Marketing</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3 class="font-family004">Quick Links</h3>
                    <ul class="import-link font-family005">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/about">About Us</a></li>
                        <li><a routerLink="/solutions">Solutions</a></li>
                        <li><a >Products</a></li>
                        <li><a>Projects</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Start Copy Right Area -->
<div class="copy-right-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <p class="font-family005">© Developed by <a href="https://bontonsoftwares.com/" target="_blank">BonTon Softwares</a></p>
            </div>

            <div class="col-lg-6 col-md-6">
                <ul class="footer-menu font-family005">
                    <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
<!-- End Copy Right Area -->