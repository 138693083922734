<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content pt-0">
            <h2 class="">Projects</h2>
            <ul class="">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Projects / e-Governance Department</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area py-5 bg-grey-light">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-8">
                <div class="services-details-content">
                    <div class="services-content">
                        <h3 class="text-c02">Crop Management System</h3>
                        <p>Tamil Nadu Government wants to have a clear picture of the crop sown and the type of irrigation brought about across all farm lands in the state. The vision of the project is to be the means to create a single, verified source of truth for Farmer and Crop data in the state that can be utilized by multiple departments and other agents in the eco-system. This crop survey will ensure consistency in records across all databases. The goal is to ensure that all systems have access to accurate and up-to-date Farmer & Crop data in a timely manner.</p>
                        <p>The overall crop survey application will have web and mobile components. The web components (including the API's to be developed) shall be in the scope of TneGA. The mobile application for data capture and geofenced validation shall be in the scope of vendors selected for outsourcing process.</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 p-md-5">
                <img src="assets/img/product/Crop.jpg" alt="" class="">
            </div>
        </div>
    </div>
</section>
<section class="services-details-area py-5">
    <div class="container">
        <div class="row mt-4">
            <div class="col-12 col-md-6 text-center p-2 px-md-5">
                <img src="assets/img/product/seniorcitizen.jpg" alt="" class="">
            </div>
            <div class="col-12 col-md-6">
                <div class="services-details-content">
                    <div class="services-content">
                        <h3 class="text-c02">Senior Citizen Social Welfare</h3>
                        <p>This App is to facilitate both the elders and their family members to search and select the concessions and services under the “Senior Citizen Scheme”, “Old Age Home” and Hospitals of Social Welfare Department.The newest concession offers or schemes, while a search function helps users to access the concession information speedily.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->

