<section class="solution-area solution-area-three white-bg pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="solution-content">
                    <div class="solution-title">
                        <h2>The Future is already here</h2>
                        <span>We Provide Complete Set Of Custom-made Services</span>
                    </div>

                    <div class="row">
                        <div class="col-lg-12 col-md-6">
                            <div class="single-solution overly-one">
                                <div class="overly-two">
                                    <!-- <h3><a routerLink="/solutions-details">Product Consultation</a></h3> -->
                                    <p>Let’s take a look at just one of the applications that would be easily adopted once the technology is rolled out to a more efficient, affordable and comfortable technological stage.</p>
                                    <span>!</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-6">
                            <div class="single-solution overly-one">
                                <div class="overly-two">
                                    <p>Once whole worlds can be simulated for the senses, the only way to assure the integrity of the public imagination will be to get the power to create those worlds out of the hands of an elite and into general circulation.</p>
                                    <span>!</span>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-lg-12 col-md-6 offset-md-3 offset-lg-0">
                            <div class="single-solution overly-one">
                                <div class="overly-two">
                                    <h3><a routerLink="/solutions-details">24/7 Technical Support</a></h3>
                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.</p>
                                    <span>03</span>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 pr-0">
                <div class="solution-img-two text-center">
                    <img src="assets/img/customers/futureHere.jpg" alt="Image">
                </div>
            </div>
        </div>
    </div>
</section>