<app-navbar-style-three></app-navbar-style-three>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content pt-0">
            <h2 class="">Projects</h2>
            <ul class="">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Projects / Police</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area py-5">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="services-details-content">
                    <div class="services-content">
                        <h3 class="text-c02">Smart Kavalar / Field Policing App</h3>
                        <p>Tracking of Field Executives in real time by Higher officials for Reporting. Creation of
                            Data bank and Geo-tagging to enable the location importance. API Integration with
                            external Databases like Vahan , Sarathi etc. The Main Functionalities are,</p>
                        <ul>
                            <li><i class="bx bx-check-circle text-c01"></i> Beat Creation / Allocation / Self assigned </li>
                            <li><i class="bx bx-check-circle text-c01"></i> Perform the Beat, Field Duties & General Tasks</li>
                            <li><i class="bx bx-check-circle text-c01"></i> Monitoring & Live Beat Tracking</li>
                            <li><i class="bx bx-check-circle text-c01"></i> Generating Customized Reports </li>
                            <li><i class="bx bx-check-circle text-c01"></i> SoS Alert</li>
                            <li><i class="bx bx-check-circle text-c01"></i> Chat & Broadcast</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 px-md-5">
                <div class="p-md-3">
                    <img src="assets/img/police1.jpg" alt="Image" class="">
                </div>
                <!-- <div class="row match-height">
                    <div class="col-6">
                        <div class="fix">
                            <a>
                                <img src="assets/img/police1.jpg" alt="Image" class="">
                            </a>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="fix">
                            <a>
                                <img src="assets/img/police2.jpg" alt="Image" class="">
                            </a>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</section>
<section class="services-details-area py-5 bg-grey-light">
    <div class="container">
        <div class="row mt-4">
            <div class="col-12 col-md-6 d-flex align-itemsc-center justify-content-center p-2 px-md-5">
                <img src="assets/img/police2.jpg" alt="" class="">
            </div>
            <div class="col-12 col-md-6">
                <div class="services-details-content">
                    <div class="services-content">
                        <h3 class="text-c02">Highway Patrol / Road Safety App</h3>
                        <p >Cutting casualty rates and making roads safer is a priority for local and national governments around the world. Accident rates in the TN have continued to decline over recent decades, thanks to a combination of high publicity campaigns and a wide variety of safety improvement schemes across all scales of the road network. However, Casualty Reduction Targets serve to highlight that preventing accidents will continue to be an important priority</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Services Details Area -->
