<app-navbar-style-two></app-navbar-style-two>

<!-- Start Page Title Area -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2 class="font-family005">Crop Management System</h2>
            <ul class="font-family005">
                <li><a routerLink="/"><i class="bx bx-home"></i> Home</a></li>
                <li class="active">Crop Management System</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Details Area -->
<div class="blog-details-area py-5">
    <div class="container">
        <div class="row">
            <div class="col12 col-md-8">
                <div class="blog-details-content">
                    <div class="blog-top-content">
                        <div class="news-content font-family004">
                            <h3 class="font-family005 mb-2">Crop Management System</h3>
                            <p>Tamil Nadu Government wants to have a clear picture of the crop sown and the type of irrigation brought about across all farm lands in the state. The vision of the project is to be the means to create a single, verified source of truth for Farmer and Crop data in the state that can be utilized by multiple departments and other agents in the eco-system. This crop survey will ensure consistency in records across all databases. The goal is to ensure that all systems have access to accurate and up-to-date Farmer & Crop data in a timely manner.</p>
                            <p>The overall crop survey application will have web and mobile components. The web components (including the API's to be developed) shall be in the scope of TneGA. The mobile application for data capture and geofenced validation shall be in the scope of vendors selected for outsourcing process.</p>
                        </div>
                        <!-- <div class="news-content font-family004 mt-3">
                            <h3 class="font-family005 mb-2">MAJORE FEATURES OF AGRICULTURE</h3>
                            <p>Real-time Comprehensive Dashboard for recording, tracking and viewing detailed crop information, status, Marketing activities.</p>
                            <p>Manure/ fertilizer application recording and mapping facility- This feature allows Users to divide and categorize their fields based on type and volume of manure application. It can also be used to record and keep track of what, when, where, and who applied manure in the fields.</p>
                            <p>Real-time activity Mapping which enables Users to view each and every on-farm tasks/actions that are planned in the future and are taking place at the moment.</p>
                            <p>Spray/Treatment scheduling functionality using which Farmers can easily schedule Sprays/treatments and track them.</p>
                        </div>
                        <div class="news-content font-family004 mt-3">
                            <h3 class="font-family005 mb-2">WHAT DID WE DO FOR THE PROJECT?</h3>
                            <p>HTML /CSS Designing and Front-end development using Typescript and AngularJS</p>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="row">
                    <div class="col-12 mb-2">
                        <div class="single-blog-post-img">
                            <a>
                                <img src="assets/img/product/Crop.jpg" alt="Image">
                            </a>
                        </div>
                    </div>
                    <!-- <div class="col-12 col-md-6">
                        <div class="single-blog-post-img">
                            <a routerLink="/blog-details">
                                <img src="assets/img/blog-details/blog-1.jpg" alt="Image" class="fix">
                            </a>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="single-blog-post-img">
                            <a routerLink="/blog-details">
                                <img src="assets/img/blog-details/blog-1.jpg" alt="Image" class="fix">
                            </a>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Blog Details Area -->