<section class="get-in-touch-area ptb-100">
    <div class="container">
        <div class="section-title white-title">
            <span class="font-family002">Get In Touch</span>
            <h2 class="font-family005">Contact Us Today To Speak With An Expert About Your Specific Needs</h2>
        </div>

        <form [formGroup]="FormData" (ngSubmit)="onSubmit()" class="get-in-touch-form">
            <div class="row">
                <div class="col-lg-6 col-12">
                    <div class="form-group">
                        <label class="font-family004">Name</label>
                        <input class="form-control" name="name" placeholder="Full Name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                        <div *ngIf="submitted && f.name.errors">
                            <small *ngIf="f.name.errors.required" class="form-text text-danger">Name is required</small>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-12">
                    <div class="form-group">
                        <label class="font-family004">Email</label>
                        <input type="text" name="email" placeholder="Enter email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                        <div *ngIf="submitted && f.email.errors" class="form-text text-danger">
                            <small *ngIf="f.email.errors.required">Email is required</small>
                            <small *ngIf="f.email.errors.email">Email must be a valid email address</small>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-12">
                    <div class="form-group">
                        <label class="font-family004">Phone</label>
                        <input name="subject" placeholder="Enter Phone" formControlName="subject" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.subject.errors }">
                        <div *ngIf="submitted && f.subject.errors">
                            <small *ngIf="f.subject.errors.required" class="form-text text-danger">Phone is required</small>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-12">
                    <div class="form-group">
                        <label class="font-family004">Company</label>
                        <input type="text" class="form-control" formControlName="message" placeholder="Enter Cpmpany" [ngClass]="{ 'is-invalid': submitted && f.message.errors }">
                        <div *ngIf="submitted && f.message.errors">
                            <small *ngIf="f.message.errors.required" class="form-text text-danger">Company is required</small>
                        </div>
                    </div>
                </div>
            </div>

            <button type="submit" class="default-btn">
                <span>Consultation</span>
            </button>
        </form>
    </div>
</section>